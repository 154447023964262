import { openWarningNotification } from "src/components";

export const errorHandling = (e: any) => {
  const msg = e?.response?.data?.msg || null;
  switch (msg) {
    case "jwt_unauthorized":
      // localStorage.removeItem("geda-token");
      return;
    case "unable_to_fetch_game":
      openWarningNotification("Cannot fetch the game data");
      break;
    case "unable_to_fetch_player":
      openWarningNotification("Cannot fetch your wallet data");
      break;
    case "prediction_has_ended":
      openWarningNotification("This prediction has ended");
      break;
    case "unable_to_predict":
    case "unable_to_draw":
      openWarningNotification("Please confirm you have enough GEDA Credit");
      break;
    case "signature_is_invalid":
      openWarningNotification("Please try again later");
      break;
    case "game_ended":
    case "draw_is_close":
      openWarningNotification("Lucky Draw is closed");
      break;
    case "already_done_task":
      openWarningNotification("You have completed this task already!");
      break;
    case "already_rt_tweet_today":
      openWarningNotification("You have done retweeted task today");
      break;
    case "already_follow_twitter":
      openWarningNotification("You have done follow our twitter task today");
      break;
    case "already_referred":
      openWarningNotification("You have added referral before");
      break;
    case "cannot_find_referral_code":
      openWarningNotification("This referral code is not valid");
      break;
    case "cannot_invite_self":
      openWarningNotification("Cannot invite yourself");
      break;
    case "twitter_bind_to_other_account":
      openWarningNotification("Your Twitter has bound to another GeDa account");
      break;
    case "not_enough_balance":
      openWarningNotification(
        "Sorry you don't have enough balance in your wallet."
      );
      break;
    case "not_enough_prizes":
      openWarningNotification("Sorry mystery box is out of prize.");
      break;
    case "exceed_max_quantity":
      openWarningNotification("Sorry please purchase legit quantity.");
      break;
    case "transaction_failed":
      openWarningNotification("Please try again later!");
      break;
    case "otp_exist_and_not_expired":
      openWarningNotification("Verification code is not exist or expired.");
      break;
    case "user_has_not_deposit_before":
      openWarningNotification("Please deposit to enable p2p transfer.");
      break;
    case "user_has_not_open_enough_cases":
      openWarningNotification("Please open more cases to enable p2p transfer.");
      break;
    default:
      break;
  }
  return msg;
};

export const isResultSuccess = (result: any) => {
  return result && result["data"] && result["data"]["success"];
};

export const refreshNewToken = (result: any) => {
  const token = result["data"]["refreshToken"] || null;
  if (!token) return;
  return localStorage.setItem("geda-token", token);
};

export const setHeader = () => {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("geda-token")}`,
    },
  };
  return config;
};
