import { useWindowSize } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { useAuthStore } from "src/stores";
import { LoadingIconV2 } from "./loading-icon-v2";
import { useAuth } from "./auth-context";
import { useGoogleLogin } from "@react-oauth/google";

const inputStyle = `py-space-4 w-full h-[40px] text-left placeholder-left bg-black-400 pl-space-4 border-black-200 rounded-xs border-solid border-[1px] placeholder-black-200 text-grey-800 placeholder-blender-medium placeholder-font-[700] placeholder-text-l font-[400] text-m md:text-xxl blender-medium placeholder-center placeholder-text-grey-600 focus:border-orange-800 focus:outline-none`;

const ssoIconStyle = "w-[48px] h-[48px] cursor-pointer";
interface IModalLogin {
  onCancel: () => void;
}

export const ModalLogin = (props: IModalLogin) => {
  const { onCancel } = props;

  const [email, setEmail] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [timer, setTimer] = useState<number>(0); // 5 minutes = 300 seconds
  const [canResend, setCanResend] = useState<boolean>(false);
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false); // Track if OTP is sent
  const [loading, setLoading] = useState<boolean>(false);

  const { getOtp, login, getProfileV2, googleLogin } = useAuthStore();
  const { setLoggedIn, setUserInfo } = useAuth();

  const size: any = useWindowSize();
  const isResponsive = isMobile || size?.width < 1080;

  useEffect(() => {
    let interval: any = null;
    if (!isOtpSent) return;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timer === 0 && isOtpSent) {
      setCanResend(true);
    }

    return () => clearInterval(interval);
  }, [timer, isOtpSent]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      remainingSeconds < 10 ? "0" : ""
    }${remainingSeconds}`;
  };

  const handleSendCode = async () => {
    if (timer > 0)
      return openWarningNotification(
        `Verification code can be resent in ${timer} seconds.`
      );

    if (!email) return openWarningNotification("Please fill in email");

    // Logic to send OTP
    const result = await getOtp(email);
    if (!result)
      return openWarningNotification(
        "Failed to send verification code, please try again later"
      );

    openSuccessNotification(
      "Please check your inbox and fill in the verification code within 5 minutes."
    );
    setCanResend(false);
    setTimer(300); // Reset timer to 5 minutes
    setIsOtpSent(true); // Mark OTP as sent
  };

  const handleLogin = async () => {
    if (!email || !otp)
      return openWarningNotification(
        "Please fill in email and verification code"
      );
    if (loading) return;
    setLoading(true);
    const result = await login({
      email: email.toLowerCase().trim(),
      otp: otp,
    });
    setLoading(false);
    if (!result)
      return openWarningNotification(
        "Login failed, please check your verification code or try again later."
      );

    const profile = await getProfileV2();
    if (!profile || typeof profile === "string") return;
    setUserInfo(profile);
    setLoggedIn(true);
    openSuccessNotification("Successfully logged in!");
    onCancel();
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (credentialResponse) => {
      try {
        if (!credentialResponse.access_token) {
          return openWarningNotification(
            "Google login failed. Please try again."
          );
        }

        setLoading(true);
        const result: any = await googleLogin(credentialResponse.access_token);
        setLoading(false);

        if (!result)
          return openWarningNotification(
            "Login failed, please try again later"
          );

        const profile = await getProfileV2();
        if (!profile || typeof profile === "string") return;
        setUserInfo(profile);
        setLoggedIn(true);

        openSuccessNotification("Successfully logged in with Google!");
        onCancel();
      } catch (error) {
        console.error("Error during Google login:", error);
        openWarningNotification("Google login failed. Please try again.");
      }
    },
  });

  const handleSSOLogin = (
    provider: "Google" | "Apple" | "Facebook" | "Telegram"
  ) => {
    return openWarningNotification(`${provider} login is coming soon!`);
  };

  return (
    <div className="w-screen h-screen z-[999] fixed top-0 left-0 flex items-center justify-center">
      <div
        className="gap-space-2 relative z-[999] w-[320px] h-[568px] md:w-[640px] md:h-[590px]  items-start"
        style={{
          backgroundImage: `url("/assets/v4/${
            !isResponsive ? "receipt-bg" : "modal-mobile-bg"
          }.png")`,
          backgroundSize: "cover",
        }}
      >
        <div className="flex flex-row items-center p-space-7 py-space-7">
          <h2 className="text-xxl md:text-xxxl text-grey-400 flex-1 mt-space-4 md:mt-space-8">
            Welcome to <span className="acpex text-orange-800">Geda</span>
          </h2>
          <img
            src="/assets/v4/modal-close.png"
            alt="modal-close"
            className="w-[30px] h-[30px] md:w-[56px] md:h-[56px] cursor-pointer"
            onClick={() => onCancel()}
          />
        </div>

        {/* Divider */}
        <div className="w-full h-[1px] bg-grey-800" />

        <div className="px-space-8 h-[350px] py-space-4 flex flex-col gap-space-4">
          <div className="flex flex-col gap-space-2">
            <p className="text-xs md:text-m">Email</p>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e?.target?.value)}
              className={inputStyle}
              placeholder="Geda_user@company.com"
            />
          </div>

          <div className="flex flex-col gap-space-2">
            <p className="text-xs md:text-m">Verification Code</p>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e?.target?.value)}
              className={inputStyle}
              placeholder="Verification Code"
            />
            <div className="flex flex-row items-center gap-space-2">
              <p className="text-xs md:text-m">
                {canResend
                  ? "Click to resend the verification code"
                  : "Click to send a verification code to your inbox"}
              </p>
              <p className="text-xs md:text-m">•</p>
              <p
                className="text-xs md:text-m text-orange-800 cursor-pointer"
                onClick={() => handleSendCode()}
              >
                {canResend
                  ? "Resend"
                  : isOtpSent
                  ? `Resend in ${formatTime(timer)}`
                  : "Send"}
              </p>
            </div>

            {/* Confirmation */}
            <div className="flex justify-center items-center py-space-4 px-space-4">
              <button
                className="border border-orange-600 w-[400px] h-[47px] bg-black-800 flex justify-center items-center rounded-xs blender-medium text-orange-600 font-[500] text-xs md:text-m"
                onClick={(e) => handleLogin()}
              >
                {loading ? <LoadingIconV2 /> : "Continue"}
              </button>
            </div>

            {/* Divder */}
            <div className="flex flex-row items-center gap-space-2">
              <div className="w-full h-[1px] bg-grey-800" />
              <p className="text-xs md:text-m text-grey-400 text-center w-[200px]">
                or log in with
              </p>
              <div className="w-full h-[1px] bg-grey-800" />
            </div>

            {/* SSO Login */}
            <div className="flex flex-row items-center justify-center p-space-2 gap-space-2 border border-[1px] border-black-200 rounded-xs border-solid bg-black-400 mt-space-2">
              <img
                src="/assets/sso/google.png"
                alt="google"
                className={ssoIconStyle}
                onClick={() => {
                  if (loading) return;
                  handleGoogleLogin();
                }}
              />
              <img
                src="/assets/sso/apple.png"
                alt="apple"
                className={ssoIconStyle}
                onClick={() => handleSSOLogin("Apple")}
              />
              <img
                src="/assets/sso/fb.png"
                alt="fb"
                className={ssoIconStyle}
                onClick={() => handleSSOLogin("Facebook")}
              />
              <img
                src="/assets/sso/tg.png"
                alt="tg"
                className={ssoIconStyle}
                onClick={() => handleSSOLogin("Telegram")}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Overlay */}
      <div className="bg-black-800/60 w-full h-full left-0 absolute"></div>
    </div>
  );
};
