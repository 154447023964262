import { Modal } from "antd";
import { CloseButton } from "./close-btn";
import moment from "moment";
import { useMemo, useState } from "react";
import { useGameStore } from "src/stores";
import { createSignature } from "src/utils/crypto";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { LoadingIcon } from "./loading-icon";
export const ModalConfirmPrediction = (props: any) => {
  const { isModalOpen, setIsModalOpen, isPicked, game, getMyPickCb, bet } =
    props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { pickTeamV2 } = useGameStore();

  const selectedTeam = useMemo(() => {
    return game[isPicked];
  }, [isPicked, game]);

  const confirmTeamPick = async () => {
    if (isLoading) return;
    if (bet < 100 || bet > 10000)
      return openWarningNotification("G-Points is out of range.");
    setIsLoading(true);
    const result = await pickTeamV2({
      id: game["_id"],
      selection: isPicked,
      signature: createSignature(`${game["_id"]}+${isPicked}`),
      size: bet,
    });
    setIsLoading(false);
    if (!result) return openWarningNotification("Please try again later");
    if (result === "picked_team_success")
      openSuccessNotification("You have picked the team, good luck!");
    getMyPickCb();
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={``}
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      centered={true}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleCancel} />

      <div className="flex flex-col justify-start align-start min-h-[200px]">
        {/* Title */}
        <h2 className="page-subtitle">Confirm Prediction</h2>
        {/* Confirmation */}
        <div className="mt-s">
          <div className={$row}>
            <p className={$label}>Match</p>
            <p className={$value}>{game?.name}</p>
          </div>
          <div className={$row}>
            <p className={$label}>Game Time</p>
            <p className={$value}>
              {moment.utc(game?.game_time).format("dddd, DD MMMM")}
            </p>
          </div>
          <div className={$row}>
            <p className={$label}>Selected Team</p>
            <p className={$value}>{selectedTeam}</p>
          </div>
          <div className={$row}>
            <p className={$label}>G-Points Used</p>
            <p className={$value}>{bet} GEDA</p>
          </div>
          {/* Confirm Button */}
          <div className="mt-m">
            <button
              className="bg-gradient-to-r from-[#F99433] to-[#FE5218] text-white  px-[20px] py-[10px] border-[0px] mx-auto w-full md:w-[300px] justify-center flex"
              onClick={() => confirmTeamPick()}
            >
              {isLoading ? <LoadingIcon color="#fff" size={20} /> : "Confirm"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const $row = "flex flex-row items-center mt-s";
const $label = "text-[#7b7b7b] min-w-[150px] content";
const $value = "content";
