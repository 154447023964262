import { useState } from "react";
import { ModalClaimForm } from "./modal-claim-form";
import moment from "moment";
import { openWarningNotification } from "./notification";

export const MyBagItem = (props: any) => {
  const { item, getMyBagCb } = props;
  let {
    prize = null,
    prize_detail = {},
    claimed = false,
    created_at = null,
  } = item;
  let { type = "", name = "", brand, image = "" } = prize_detail;
  prize_detail.tagList =
    typeof prize_detail?.tags === "string" ? prize_detail?.tags.split(",") : [];

  const [openModal, setOpenModal] = useState(false);

  const handleClaim = (id: string) => {
    if (id === "matrix-firebox") {
      return openWarningNotification(
        "We will distribute to your address soon!"
      );
    }
    setOpenModal(true);
  };

  const getPrizeName = (id: string) => {
    if (id === "matrix-firebox") {
      if (prize) {
        let tokenName = "$FIRE";
        if (name.indexOf("USDT") > -1) tokenName = "$USDT";
        return `${prize} ${tokenName}`;
      }
    }
    return name;
  };

  const getPrizeImg = (id: string) => {
    if (id === "acident8_1") {
      return "/assets/games/BeneCatwiches.png";
    }
    if (id === "acident8gw_1") {
      return "/assets/games/acident8gw.jpeg";
    }
    if (id === "nyannft_1") {
      return "/assets/games/nyan.png";
    }
    if (id === "guardiannft_1") {
      return "/assets/games/nyan_alt.png";
    }
    if (id === "mixmobnft_1") {
      return "/assets/games/mixmob.png";
    }
    if (id === "pomeriumnft_1") {
      return "https://i.seadn.io/s/raw/files/7401eca2d039cdd0f2549542afab569f.png?auto=format&dpr=1&w=384";
    }
    if (id === "pomeriumtoken_1") {
      return "https://pomerium.space/assets/pmg-icon1-f0393b95.gif";
    }
    if (id === "iagentnode_1") {
      return "https://pbs.twimg.com/profile_images/1755138261507534848/ze2Py5L__400x400.jpg";
    }
    if (id === "firebox_1") return "/assets/games/firebox.jpeg";
    if (id === "kukubox_1") return "/assets/games/kukubox.jpeg";
    if (id && id.indexOf("usdt") > -1) return "/assets/games/usdt_img.jpeg";
    if (id === "ssnft_1") return "/assets/games/ssnft.gif";
    if (id === "cellulanft_1")
      return "https://pbs.twimg.com/profile_images/1775784317421318144/PUDhpeaM_400x400.jpg";
    if (id === "bsdwl")
      return "https://pbs.twimg.com/profile_images/1760335903497580544/8t4B7SGH_400x400.jpg";
    return image;
  };

  const getClaimTxt = (id: string) => {
    if (id === "matrix-firebox") {
      return "DISTRIBUTE SOON";
    }
    return "CLAIM NOW";
  };

  return (
    <div className="bg-[#1F232F] rounded-m overflow-hidden ">
      {/* Type */}
      <div className="w-full bg-[#252935] px-m py-s flex-row flex">
        <p className="small-content flex-1 uppercase">{type}</p>
        {created_at && (
          <p className="small-content">{moment(created_at).format("DD MMM")}</p>
        )}
      </div>
      <div className="">
        <div className="mt-s px-m">
          <p className="small-title whitespace-nowrap overflow-hidden text-ellipsis">
            {getPrizeName(prize_detail?.game_id)}
          </p>
          <p className="small-content mt-xxs">{brand}</p>
        </div>
        <div className="px-m mt-s">
          <div className="p-m w-[100%] border-[0px] border-[#2F333F] bg-[#252935] rounded-m overflow-hidden">
            <img
              src={getPrizeImg(prize_detail?.game_id)}
              alt="prize-img"
              className="w-full mx-auto object-fit rounded-m"
            />
          </div>
        </div>

        {/* Confirm Button */}
        <div className="mt-s px-s">
          {!claimed && (
            <button
              className="bg-gradient-to-r from-[#F99433] to-[#FE5218] text-white px-m py-xs border-[0px] mx-auto justify-center flex w-full my-s"
              onClick={() => handleClaim(prize_detail?.game_id)}
            >
              {getClaimTxt(prize_detail?.game_id)}
            </button>
          )}

          {claimed && (
            <div className="flex flex-1 justify-center items-center pb-s">
              <p className="content relative top-[-2px]">CLAIMED</p>
            </div>
          )}
        </div>
      </div>

      <ModalClaimForm
        isModalOpen={openModal}
        setIsModalOpen={setOpenModal}
        item={item}
        getMyBagCb={getMyBagCb}
      />
    </div>
  );
};
