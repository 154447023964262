import { useMemo } from "react";

export const RaffleV3Card = (props: any) => {
  const {
    label = "label",
    mainTxt = "",
    iconImg = "",
    bgColor = "#1D1D1D",
    showMainTxt,
    showImg,
    bgImg,
    prizeType,
  } = props;

  const isSmallerFontSize = useMemo(() => {
    if (!prizeType) return false;
    if (["acident8", "acident8gw"].indexOf(prizeType) > -1) {
      return true;
    }
    if (prizeType.indexOf("raffle") > -1) {
      return true;
    }
    return false;
  }, [prizeType]);

  return (
    <div className="w-[205px]">
      <div
        className={`flex flex-col w-[180px] min-w-[97px] h-[220px] 
        md:w-[180px] md:min-w-[180px] md:h-[220px] 
        p-s md:p-s relative mx-auto
        overflow-hidden rounded-m`}
        style={{
          background: bgColor,
          ...(bgImg && {
            backgroundImage: `url(${bgImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }),
        }}
      >
        <h3
          className={
            "card-title text-center md:mt-[15px] mb-1 md:mb-4 z-[5] relative"
          }
          style={{
            ...(isSmallerFontSize && {
              fontSize: "20px",
            }),
          }}
        >
          {label}
        </h3>

        {mainTxt !== "" && showMainTxt && (
          <p className="card-big-content text-center z-10">{mainTxt}</p>
        )}

        {iconImg && showImg && (
          <img
            src={iconImg}
            alt="logo-bg"
            className="object-contain h-[136px] absolute z-0 bottom-[6px] md:bottom-[15px] mx-auto inset-x-0"
          />
        )}

        {/* Overlay */}
        <div className="absolute w-full h-[60%] bg-gradient-to-b from-[#1a1a1a] to-transparent z-1 left-0 top-0" />
      </div>
    </div>
  );
};
