import axios from "axios";
import * as Configs from "../config";
import { errorHandling, setHeader } from "./api";

export class GameStore {
  async getGame(id: string) {
    const url = `${Configs.API_URL}/v1/game?gameId=${id}`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getMyPick(id: string) {
    const url = `${Configs.API_URL}/v1/game/prediction/my-pick?id=${id}`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getPredictionAnalysis(id: string) {
    const url = `${Configs.API_URL}/v1/game/prediction/analysis?id=${id}`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getPredictionAnalysisV2(id: string) {
    const url = `${Configs.API_URL}/v2/game/prediction/analysis?id=${id}`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getPredictionPotentialV2(id: string, side: string, bet: number) {
    const url = `${Configs.API_URL}/v2/game/prediction/potential?id=${id}&side=${side}&bet=${bet}`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["data"]["potential_winning"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getPredictionList(id: string) {
    const url = `${Configs.API_URL}/v1/game/prediction/list?gameId=${id}`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getGameCategory() {
    const url = `${Configs.API_URL}/v1/game/prediction/category`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getGameSeries(ids?: string) {
    const query = ids ? `?game_ids=${ids}` : "";
    const url = `${Configs.API_URL}/v1/game/prediction/game-list${query}`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getPrizeContent(id: string) {
    const url = `${Configs.API_URL}/v1/game/lucky-draw/content?gameId=${id}`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getPrizeList(id: string) {
    const url = `${Configs.API_URL}/v1/game/lucky-draw/list?gameId=${id}`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async pickTeam(payload: any) {
    const url = `${Configs.API_URL}/v1/game/prediction/pick`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["msg"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async pickTeamV2(payload: any) {
    const url = `${Configs.API_URL}/v2/game/prediction/pick`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["msg"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async startDraw(payload: any) {
    const url = `${Configs.API_URL}/v1/game/lucky-draw/spin`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async startDrawV2(payload: any) {
    const url = `${Configs.API_URL}/v1/game/lucky-draw/open`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_GAME = "gameStore";
