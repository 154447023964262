import { Modal } from "antd";
import { CloseButton } from "./close-btn";
import { history } from "src/stores";
export const ModalConfirmAirdropRaffleRetrieved = (props: any) => {
  const { isModalOpen, setIsModalOpen, prize, setPrize } = props;

  const handleCancel = () => {
    setIsModalOpen(false);
    setPrize({});
    // window?.location?.reload();
  };

  let { type } = prize;

  let bgImg = "/assets/v3/gega-icon.png";
  let value = "";
  let prizeType = "";
  let showDesc = false;
  if (prize && type) {
    [prizeType, value] = type.split("_");

    switch (prizeType) {
      case "energy":
        bgImg = "/assets/v3/flash-icon.png";
        value += "%";
        break;

      case "multiplier":
        value += "%";
        break;

      case "fire":
        bgImg = "/assets/v3/FIRE.png";
        break;

      case "nyannft":
        bgImg = "/assets/games/nyan.png";
        showDesc = true;
        break;

      case "guardiannft":
        bgImg = "/assets/games/nyan_alt.png";
        showDesc = true;
        break;

      case "mixmobnft":
        bgImg = "/assets/games/mixmob.png";
        showDesc = true;
        break;

      case "pomeriumnft":
        bgImg =
          "https://i.seadn.io/s/raw/files/7401eca2d039cdd0f2549542afab569f.png?auto=format&dpr=1&w=384";
        showDesc = true;
        break;

      case "pomeriumtoken":
        bgImg = "https://pomerium.space/assets/pmg-icon1-f0393b95.gif";
        showDesc = true;
        break;

      case "iagentnode":
        bgImg =
          "https://pbs.twimg.com/profile_images/1755138261507534848/ze2Py5L__400x400.jpg";
        showDesc = true;
        break;

      case "kukubox":
        bgImg = "/assets/games/kukubox.jpeg";
        showDesc = true;
        break;

      case "firebox":
        bgImg = "/assets/games/firebox.jpeg";
        showDesc = true;
        break;

      case "ssnft":
        bgImg = "/assets/games/ssnft.gif";
        showDesc = true;
        break;

      case "cellulanft":
        bgImg =
          "https://pbs.twimg.com/profile_images/1775784317421318144/PUDhpeaM_400x400.jpg";
        showDesc = true;
        break;

      case "bsdwl":
        bgImg =
          "https://pbs.twimg.com/profile_images/1760335903497580544/8t4B7SGH_400x400.jpg";
        showDesc = true;
        break;

      default:
        if (prizeType.indexOf("usdt") > -1) {
          bgImg = "/assets/games/usdt_img.jpeg";
          showDesc = true;
        }
        break;
    }
  }

  const withBag =
    [
      "acident8",
      "acident8gw",
      "nyannft",
      "guardiannft",
      "pomeriumnft",
      "pomeriumtoken",
      "iagentnode",
      "kukubox",
      "firebox",
      "usdt10",
      "usdt50",
      "usdt100",
      "ssnft",
      "cellulanft",
      "bsdwl",
    ].indexOf(prizeType) > -1;

  return (
    <Modal
      title={``}
      visible={isModalOpen}
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={null}
      centered={true}
      bodyStyle={{
        backgroundColor: "black",
        borderRadius: 10,
      }}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleCancel} />

      <div className="flex flex-col justify-center items-center min-h-[200px]">
        {/* Title */}
        <h5 className="font-[400] text-white text-[20px] uppercase">
          Congratulations
        </h5>
        {/* Confirmation */}
        <div className="mt-m">
          <p className={$label}>
            You have received{" "}
            <span className="text-[#FE5218]">
              {value} x {prize?.name}
            </span>
            !
          </p>
          <img
            src={bgImg}
            alt="prize-img"
            className="w-lg mx-auto object-cover mt-m max-w-[450px] aspect-square rounded-s"
          />
          {/* Notes */}
          {showDesc && (
            <p className="mt-s text-center small-content">
              Congratulations! Please fill out this form immediately to secure
              your spot. If you do not complete this form in time, you may miss
              out on this opportunity!"
            </p>
          )}

          {/* Confirm Button */}
          <div className="mt-m">
            {withBag && (
              <>
                <button
                  className="bg-gradient-to-r from-v3primary to-v3secondary text-white px-m py-xs border-[0px] mx-auto w-full md:w-[300px] justify-center flex"
                  onClick={() => history.push("/my-bag")}
                >
                  GO TO BAG AND FILL FORM
                </button>
                <button
                  className="bg-white text-v3primary px-m py-xs border-[0px] mx-auto w-full md:w-[300px] justify-center flex mt-s"
                  onClick={() => handleCancel()}
                >
                  CLOSE
                </button>
              </>
            )}

            {!withBag && (
              <button
                className="bg-gradient-to-r from-v3primary to-v3secondary text-white  px-m py-xs border-[0px] mx-auto w-full md:w-[300px] justify-center flex"
                onClick={() => handleCancel()}
              >
                CLOSE
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const $label = "text-white text-center";
