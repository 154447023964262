import { useState } from "react";
import { LoadingIcon } from "./loading-icon";
const imgPath = `/assets/v3/`;
export const GedaBtn = (props: any) => {
  const {
    action = () => null,
    btnImg = "empty-primary-btn.png",
    btnActiveImg = "empty-white-btn.png",
    txt = "Button",
    txtColor = "#fff",
    txtActiveColor = "#FE5219",
    loading = false,
    customStyle = "",
  } = props;
  const [active, setActive] = useState<boolean>(false);

  return (
    <div
      className={`${$menuImgStyle} relative flex items-center justify-center ${customStyle}`}
      style={{
        backgroundImage: `url('${imgPath}${active ? btnActiveImg : btnImg}')`,
        backgroundSize: "cover",
      }}
      onClick={!loading ? action : undefined}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      {loading ? (
        <LoadingIcon color="#FE5219" />
      ) : (
        <p
          className="blender-medium overflow-hidden text-ellipsis text-[13px] 2xl:text-[20px] leading-[16px] 2xl:leading-[24px]"
          style={{
            color: active ? txtActiveColor : txtColor,
          }}
        >
          {txt}
        </p>
      )}
    </div>
  );
};

const $menuImgStyle =
  "w-[121px] 2xl:w-[180px] h-[41px] 2xl:h-[61px] cursor-pointer";
