export const SwitchButton = (props: any) => {
  const { tabs, tab, setTab, position = "start" } = props;

  if (!tabs) return null;

  const getName = (item: string) => {
    switch (item) {
      case "all":
        return "All";
      case "web3":
        return "Web3";
      case "web2":
        return "Web2";
      default:
        return item;
    }
  };
  return (
    <div
      className={`flex flex-row items-center space-x-[15px]`}
      style={{ justifyContent: position }}
    >
      {tabs.map((item: any, i: any) => {
        return (
          <button
            key={i}
            className={`bg-white text-black px-m py-xs border-0 blender-medium text-[12px] 2xl:text-[20px]`}
            onClick={() => setTab(item)}
            style={{
              ...(tab === item && {
                background: "#FE5219",
                color: "#fff",
              }),
            }}
          >
            {getName(item)}
          </button>
        );
      })}
    </div>
  );
};
