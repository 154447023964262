export const getMissionBackgroundImage = (
  type: string,
  target: string,
  windowSize: any
) => {
  switch (type) {
    case "daily_login":
      return "/assets/ticket_bg.jpg";

    case "referral_program":
    case "multiplier_referral":
      return `/assets/v3/reward-referral${
        windowSize?.width < 800 ? "-mobile" : ""
      }.png`;

    case "like_tweet":
    case "rt_tweet":
      if (target === "1791287563317285124")
        return "/assets/games/pome-village.jpg";
      if (target === "1796467158379319609")
        return "https://pbs.twimg.com/profile_images/1760335903497580544/8t4B7SGH_400x400.jpg";
      break;

    case "follow_twitter":
      // Dan
      if (target === "1201800022896988160")
        return "https://pbs.twimg.com/profile_images/1786717701874032641/7cprUt0__400x400.jpg";
      // Nyan
      if (target === "1422280869788622848") return "/assets/games/nyan.png";
      // Ryan
      if (target === "1508331396397699075")
        return "https://pbs.twimg.com/profile_images/1582230984917155840/4xXfjsKM_400x400.jpg";
      // MrKHC
      if (target === "1509524806965862408")
        return "https://pbs.twimg.com/profile_images/1756179723527622656/AeRdj39T_400x400.jpg";
      // Angry Easily
      if (target === "239294262")
        return "https://pbs.twimg.com/profile_images/1548905991068008448/rODqwEpx_400x400.jpg";
      // MixMob
      if (target === "1438363601329291268")
        return "https://pbs.twimg.com/profile_images/1663943707132067841/9nwPuCrR_400x400.png";
      // Pomerium_Space
      if (target === "1489543400344850432")
        return "/assets/games/pome-village.jpg";
      // iAgent
      if (target === "1706872137477193728")
        return "https://pbs.twimg.com/profile_banners/1706872137477193728/1707292446/1500x500";
      // Pomerium Space CMO
      if (target === "1352724981516406785")
        return "/assets/games/pome_phase2.jpg";
      // SS Twitter
      if (target === "1575751670117462017")
        return "https://pbs.twimg.com/profile_images/1616885999921147910/AGTxNT5t_400x400.jpg";
      // SS Owner
      if (target === "1277608999022555137")
        return "https://pbs.twimg.com/profile_images/1742190538810671104/z-OH0gAH_400x400.png";
      // Cellula
      if (target === "1649236725250813955")
        return "https://pbs.twimg.com/profile_banners/1649236725250813955/1712233444/1500x500";
      // BSD
      if (target === "1567724840274264064")
        return "https://pbs.twimg.com/profile_images/1760335903497580544/8t4B7SGH_400x400.jpg";
      // Default
      return `/assets/v3/reward-geda.png`;
    case "z_predict_games":
      return `/assets/v2/Game_01.jpg`;

    case "nyan_download":
      return `https://cdn1.epicgames.com/spt-assets/64502ad36cbc499399e9b795922446a4/nyan-heroes-10kj9.jpg?h=270&quality=medium&resize=1&w=480`;

    case "nyan_mission":
      return `/assets/games/nyan_alt.png`;

    case "mixmob_download_android":
    case "mixmob_download_ios":
    case "mixmob_download":
      return "https://assets-global.website-files.com/645aacb0e2b5dc8254a63e55/65820c7bdf19c81c870ce692_Racer1%20-%20v3.webp";

    case "visit_pome_village":
      return "/assets/games/pome-village.jpg";

    case "pome_tg":
    case "pome_discord":
    case "pome_website":
      return "/assets/games/pome_phase2.jpg";

    case "ss_discord":
    case "ss_website":
      return "/assets/games/SS_logo.png";

    case "cellula_discord":
      return "https://pbs.twimg.com/profile_banners/1649236725250813955/1712233444/1500x500";

    case "okx_tgbot":
      return "/assets/games/okx.jpeg";

    case "okx_form":
      return "/assets/games/okx_logo.jpeg";
    default:
      break;
  }
  return `/assets/v3/reward-geda.png`;
};

export const getMissionPeriodTypeLabelBackgroundColor = (
  mission_type: string,
  completed: boolean = false
) => {
  let bgColor = "";
  switch (mission_type) {
    case "cycle":
      bgColor = "#1371FF";
      break;
    case "once":
      bgColor = "#FE5219";
      break;
    case "daily":
      bgColor = "#00AF11";
      break;
  }

  if (completed) {
    bgColor = "#fff";
  }

  return bgColor;
};

export const getMissionPeriodTypeLabelColor = (
  mission_type: string,
  completed: boolean = false
) => {
  let color = "#fff";

  if (completed) {
    color = "#000";
  }

  return color;
};

export const getMissionPeriodTypeLabelText = (
  mission_type: string,
  completed: boolean = false
) => {
  switch (mission_type) {
    case "cycle":
      mission_type = "seasonal";
      break;
  }

  if (completed) {
    mission_type = "FINISHED";
  }

  mission_type = mission_type.toUpperCase();

  return mission_type;
};
