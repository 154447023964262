import {
  LoadingIcon,
  SwitchButton,
  openWarningNotification,
} from "../components";
import { useEffect, useState } from "react";
import { AuthStore } from "src/stores/auth";
import { useAccount } from "wagmi";
import { RAFFLES_LIST } from "src/config/raffles";
import { history } from "src/stores";
import { ContainerV2 } from "src/components/container-v2";
import { useWindowSize } from "@uidotdev/usehooks";

const tabs = ["all", "web3", "web2"];

export const RaffleV2 = (props: any) => {
  const [allLoaded, setAllLoaded] = useState<boolean>(false);
  const [profile, setProfile] = useState<{} | null>(null);
  const [data, setData] = useState(RAFFLES_LIST);
  const [tab, setTab] = useState("all"); // Default to be all

  const size: any = useWindowSize();
  const { address } = useAccount();
  const authStore = new AuthStore();

  useEffect(() => {
    // Fetch Profile
    if (!address) {
      setProfile({});
    }
    if (address) {
      setTimeout(() => {
        authStore.getProfile().then((user: any) => {
          if (!user) return;
          setProfile(user);
        });
      }, 1000);
    }
    // eslint-disable-next-line
  }, [address]);

  useEffect(() => {
    // if (!profile) return;
    setAllLoaded(true);
  }, [profile]);

  useEffect(() => {
    if (tab === "all") return setData(RAFFLES_LIST);
    const data = RAFFLES_LIST.filter((item) => item?.type.includes(tab));
    return setData(data);
  }, [tab, setData]);

  return (
    <ContainerV2>
      <div className="flex flex-col pb-m overflow-hidden mt-l">
        {/* Main Content */}

        {!allLoaded && (
          <div className="w-full h-full flex flex-1 justify-center items-center relative z-[15]">
            <LoadingIcon />
          </div>
        )}

        {allLoaded && (
          <div className="relative z-[15]">
            <h1 className="page-title flex-1 relative z-[1000]">
              Lucky <span className="text-v2primary">Raffle</span>
            </h1>
            {/* Filter Tab */}
            <div className="mt-m">
              <SwitchButton tabs={tabs} tab={tab} setTab={setTab} />
              <div className="mt-m grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-m">
                {data.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="flex flex-row  md:flex-col lg:flex-col items-start lg:items-center justify-between 
                w-full h-[105px] md:h-auto cursor-pointer rounded-m overflow-hidden relative bg-[#1D1D1D]"
                      onClick={() => {
                        item?.isSelectable &&
                          history.push(`/raffle/${item?.gameId}`);
                        !item?.isSelectable &&
                          openWarningNotification("Coming Soon");
                      }}
                    >
                      {/* Image */}
                      <div className="max-w-[105px] md:max-w-full w-[100%] border-[0px] border-[#2F333F] bg-[#252935] overflow-hidden">
                        <img
                          src={item?.avatar}
                          alt="prediction-img"
                          className="max-w-[105px] md:max-w-full w-full mx-auto object-cover aspect-square"
                        />
                      </div>

                      {/* Title */}
                      <div className="p-s flex-1">
                        <p className="big-content">{item?.name}</p>
                        {/* Description */}
                        <div className="flex flex-row">
                          <p className="text-[#fff] whitespace-wrap overflow-hidden text-ellipsis flex-1 blender-medium text-[10px] sm:text-[12px] mt-xxs">
                            {item?.desc}{" "}
                            {!item?.isSelectable && "(Coming Soon)"}
                          </p>
                        </div>
                        {/* Raffle Now */}
                        {size.width >= 1080 && (
                          <div className="flex flex-row mt-m">
                            <p className="big-content">OPEN NOW {">"}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </ContainerV2>
  );
};
