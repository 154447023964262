import { Modal } from "antd";
import { CloseButton } from "./close-btn";
import { ReactComponent as CloseIcon } from "../svg-icons/white-close.svg";

export const HoverModal = (props: any) => {
  const { showUp = false, children, setIsModalOpen } = props;

  const handleOk = () => {
    setIsModalOpen && setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen && setIsModalOpen(false);
  };

  return (
    <Modal
      title={``}
      visible={showUp}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      centered={true}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleCancel} closeIcon={<CloseIcon />} />
      <div className="flex flex-col justify-center align-center min-h-[200px]">
        {children}
      </div>
    </Modal>
  );
};
