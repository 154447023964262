import { useCallback, useEffect, useMemo, useState } from "react";
import RoulettePro from "react-roulette-pro";
import { RaffleV3Card } from "./raffle-v3-card";
import { RaffleV3Action } from "./raffle-v3-action";
import { openErrorNotification, openWarningNotification } from "./notification";
import "react-roulette-pro/dist/index.css";
import { ModalConfirmAirdropRaffleRetrieved } from "./modal-confirm-airdrop-raffle-retrieved";

import getRandomIntInRange from "src/utils/getRandomIntInRange";
import { useAirdropStore } from "src/stores";
import { createSignature } from "src/utils/crypto";
import { getRandomIndexByPropertyValue } from "src/utils";
import { useWeb3Modal } from "@web3modal/react";
// import { writeContract } from "@wagmi/core";
// import { useNetwork, useSwitchNetwork } from "wagmi";

import { getEarnPrizeItemRenderParameterSet } from "src/utils/earn";

const css = document.createElement("style");
css.textContent = `
.roulette-pro-regular-design-top::before {
border-top :8px solid #FE5219 !important
}

.roulette-pro-regular-design-top::after{
border-bottom :8px solid #FE5219 !important
}
.roulette-pro-regular-design-top.horizontal{
  background : #FE5219 !important;
  z-index: 999;
  left:6%
}
`;

export const RaffleV3 = (props: any) => {
  const {
    airdropProfile,
    userId,
    getProfileCb,
    getAirdropProfileCb,
    address,
    profile,
    prizeList,
  } = props;
  const [mode, setMode] = useState<string | null>(null);
  const [isDrawn, setIsDrawn] = useState<boolean>(false);
  const [start, setStart] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [prize, setPrize] = useState<{} | null>({});
  const [prizeIndex, setPrizeIndex] = useState<number>(0);
  // Inside the component
  const [openLuckyDrawRetrievedModal, setOpenLuckyDrawRetrievedModal] =
    useState<boolean>(false);

  const [loading, setLoading] = useState(false);
  const [tryItLoading, setTryItLoading] = useState(false);

  const { airdropRaffle } = useAirdropStore();
  const { open } = useWeb3Modal();

  const handlePrizeDefined = useCallback(() => {
    setSpinning(false);

    if (mode === "spin" && isDrawn) {
      try {
        setPrize(prizeList[prizeIndex]);
        setLoading(false);
        setOpenLuckyDrawRetrievedModal(true);
        getProfileCb();
        getAirdropProfileCb();
      } catch (e) {
        console.log("Error:", e);
      }
    }

    if (mode !== "spin") {
      setTryItLoading(false);
    }
  }, [prizeIndex, prizeList, mode, isDrawn, getProfileCb, getAirdropProfileCb]);

  const API = useMemo(
    () => ({
      getTryPrizeIndex: async () => {
        const randomPrizeIndex = getRandomIntInRange(0, prizeList.length - 1);
        return randomPrizeIndex;
      },
      getRealPrizeIndex: async () => {
        try {
          console.log("Enter spin");
          const prize = await airdropRaffle({
            id: userId,
            signature: createSignature(`${userId}`),
          });

          if (!prize) {
            openErrorNotification("Please try again later");
            return getRandomIntInRange(0, prizeList.length - 1);
          }

          let idx = getRandomIndexByPropertyValue(
            prizeList,
            "_id",
            prize["_id"]
          );
          let times = 0;
          const margin = 100;
          while (
            idx >= prizeIndex - margin &&
            idx <= prizeIndex + margin &&
            times < 3
          ) {
            idx = getRandomIndexByPropertyValue(prizeList, "_id", prize["_id"]);
            times++;
          }
          if (idx < 0) {
            openErrorNotification("Please try again later");
          }
          if (idx >= 0) {
            setIsDrawn(true);
          }
          return idx > -1 ? idx : getRandomIntInRange(0, prizeList.length - 1);
        } catch (err) {
          // Handle error
        }
      },
    }),
    [prizeList, airdropRaffle, userId, prizeIndex, setIsDrawn]
  );

  useEffect(() => {
    if (!spinning || !prizeList.length) {
      return;
    }

    const prepare = async () => {
      const newPrizeIndex =
        mode === "try"
          ? await API.getTryPrizeIndex()
          : await API.getRealPrizeIndex();
      setPrizeIndex(newPrizeIndex);
      setStart(false);
      setTryItLoading(false);
    };

    prepare();
    // eslint-disable-next-line
  }, [spinning, prizeList]);

  useEffect(() => {
    if (!prizeIndex || start) {
      return;
    }

    setStart(true);
  }, [prizeIndex, start]);

  const prepareSpin = async (type: string) => {
    if (spinning) return;
    setIsDrawn(false);
    setMode(type);
    setPrizeIndex(0);
    if (type === "spin") {
      if (!address) {
        open();
        return;
      }

      if (!profile?.twitter_connected) {
        openWarningNotification("Please authorize Twiiter Login");
        return;
      }

      if (airdropProfile?.tickets < 1) {
        openWarningNotification("You do not have ticket to spin!");
        return;
      }

      if (userId) {
        setLoading(true);
        setSpinning(true);
      }
    }

    if (type !== "spin") {
      try {
        // if (chain?.id !== 80001) {
        //   switchNetwork?.(80001);
        // }
        // await writeContract({
        //   abi: require("../abi/actionAbi.json"),
        //   address: "0x2FFF85719D872c4052E48695508C9cf6925F0922",
        //   functionName: "createActionLog",
        //   args: ["123", address, "spin", "123", "spin"],
        // });
      } catch (e) {
        console.log("e", e);
        return;
      }

      setTryItLoading(true);
      setSpinning(true);
    }
  };

  return (
    <div className="mt-m">
      <h1 className="page-subtitle">lucky spin</h1>
      {/* airdrop items */}
      <div className="relative w-full">
        <RoulettePro
          start={start}
          prizes={prizeList}
          prizeIndex={prizeIndex}
          spinningTime={3}
          onPrizeDefined={handlePrizeDefined}
          options={{
            stopInCenter: true,
            withoutAnimation: false,
          }}
          classes={{
            wrapper: "roulette-pro-wrapper-additional-styles",
          }}
          defaultDesignOptions={{ prizesWithText: true }}
          soundWhileSpinning="/assets/spin_v3.mp3"
          prizeItemRenderFunction={(item: any) => {
            let { name, _id } = item;

            let prizeItemRenderParameterSet =
              getEarnPrizeItemRenderParameterSet(item, prizeList, prizeIndex);

            return (
              <RaffleV3Card
                key={_id}
                label={name}
                iconImg={prizeItemRenderParameterSet?.iconImg}
                mainTxt={prizeItemRenderParameterSet?.mainTxt}
                bgColor={prizeItemRenderParameterSet?.bgColor}
                showMainTxt={prizeItemRenderParameterSet?.showMainTxt}
                showImg={prizeItemRenderParameterSet?.showImg}
                bgImg={prizeItemRenderParameterSet?.bgImg}
                prizeType={prizeItemRenderParameterSet?.prizeType}
              />
            );
          }}
        />
        <div className="absolute inset-y-0 left-0 w-[120px] bg-gradient-to-r from-black to-transparent z-[999]" />
        {/* Left shadow */}
        <div className="absolute inset-y-0 right-0 w-[120px] bg-gradient-to-l from-black to-transparent z-[999]" />
        {/* Right shadow */}
      </div>

      <ModalConfirmAirdropRaffleRetrieved
        isModalOpen={openLuckyDrawRetrievedModal}
        setIsModalOpen={setOpenLuckyDrawRetrievedModal}
        prize={prize}
        setPrize={setPrize}
      />

      {/* action bar */}
      <RaffleV3Action
        prepareSpin={prepareSpin}
        airdropProfile={airdropProfile}
        loading={loading}
        tryItLoading={tryItLoading}
        prizeList={prizeList}
      />
    </div>
  );
};
