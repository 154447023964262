interface ITag {
  tag: string;
}

export const Tag = (props: ITag) => {
  const { tag } = props;
  if (!tag) return null;
  let backgroundColor = "white";
  let textColor = "black";
  if (tag.toLowerCase() === "web3") {
    backgroundColor = "#FE5219";
    textColor = "white";
  }

  if (tag.toLowerCase() === "web2") {
    backgroundColor = "white";
    textColor = "black";
  }

  if (tag === "Live") {
    backgroundColor = "Red";
    textColor = "white";
  }

  return (
    <div
      className={`flex items-center justify-center w-fit h-[20px] md:h-[24px] md:w-[63px] z-[999] px-xs rounded-[4px] mr-xs`}
      style={{
        backgroundColor,
        ...(backgroundColor === "transparent" && {
          borderWidth: 1,
          borderColor: "white",
        }),
      }}
    >
      <p
        className="blender-medium small-content"
        style={{
          color: textColor,
        }}
      >
        {tag.toUpperCase()}
      </p>
    </div>
  );
};
