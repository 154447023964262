import { useMemo, useState } from "react";
import { GedaBtn } from "src/components/geda-btn";
import { ModalAirdropRafflePrizeList } from "./modal-airdrop-raffle-prize-list";

const $btnStyle = `
  cursor-pointer
  w-[85px] 2xl:w-[125px] 
  rounded-[5px] 2xl:rounded-[12px] 
  h-[29px] 2xl:h-[56px] 
  flex flex-row items-center 
  justify-center 
  text-[12px] 2xl:text-[20px] 
  leading-[14px] 2xl:leading-[24px] 
  blender-medium
`;

export const RaffleV3Action = (props: any) => {
  const { prepareSpin, airdropProfile, loading, tryItLoading, prizeList } =
    props;

  const [open, setOpen] = useState(false);

  const formattedPrizeList = useMemo(() => {
    if (!prizeList || prizeList.length === 0) return [];
    let uniqueIds = new Set();
    let uniqueArray = prizeList
      .filter((obj: any) => {
        if (!uniqueIds.has(obj._id)) {
          uniqueIds.add(obj._id);
          return true;
        }
        return false;
      })
      .sort((a: any, b: any) => a.probability - b.probability);
    return uniqueArray;
  }, [prizeList]);

  return (
    <div className="flex flex-row xl:justify-end items-center mt-m">
      {/* ticket */}
      <div className="flex flex-row desktop">
        {[...Array(Math.min(airdropProfile?.tickets || 0, 20))].map((_, i) => (
          <img
            alt="logo"
            key={i}
            className={`min-w-[27px] w-[27px] 2xl:min-w-[41px] 2xl:w-[41px] h-[27px] 2xl:h-[41px] ${
              airdropProfile?.tickets > 1 ? "-ml-s" : ""
            }`}
            src={"/assets/v3/ticket-white.png"}
          />
        ))}
      </div>

      {/* ticket amount */}
      <div className="flex flex-col xl:ml-5 flex-1 xl:flex-none">
        <p className="small-title">
          SPIN TICKETS LEFT: {airdropProfile?.tickets || "0"}
        </p>
        <p
          className="content underline cursor-pointer"
          onClick={() => setOpen(true)}
        >
          PRIZES & POSSIBILITIES
        </p>
      </div>

      {/* button */}

      <div className="flex flex-row items-center justify-center relative z-[10] space-x-s ml-m mobile">
        <button
          onClick={() => prepareSpin("try")}
          className={`${$btnStyle} bg-white text-black`}
        >
          TRY IT
        </button>
        <button
          onClick={() => prepareSpin("spin")}
          className={`${$btnStyle}  bg-v2primary text-white border-0`}
        >
          SPIN
        </button>
      </div>

      <div className="flex desktop">
        {/* try it */}
        <div className="px-s 2xl:px-m">
          <GedaBtn
            txt={"TRY IT"} // Twitter Connected will show Referrals
            btnImg="empty-white-btn.png"
            btnActiveImg="empty-primary-btn.png"
            txtColor="black"
            txtActiveColor="#fff"
            action={() => prepareSpin("try")}
            loading={tryItLoading}
            customStyle={"w-[120px] 2xl:w-[140px]"}
          />
        </div>

        {/* spin it */}
        <GedaBtn
          txt={"SPIN IT"} // Twitter Connected will show Referrals
          btnActiveImg="empty-white-btn.png"
          btnImg="empty-primary-btn.png"
          txtColor="#fff"
          txtActiveColor="black"
          action={() => prepareSpin("spin")}
          loading={loading}
          customStyle={"w-[120px] 2xl:w-[140px]"}
        />
      </div>

      {/* Modal */}
      <ModalAirdropRafflePrizeList
        isModalOpen={open}
        setIsModalOpen={setOpen}
        formattedPrizeList={formattedPrizeList}
      />
    </div>
  );
};
