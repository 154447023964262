export const RaffePrize = (props: any) => {
  const { prize, image = "" } = props;
  const { type = "", probability = null, name = "" } = prize;
  let [prizeType] = type.split("_");

  return (
    <div className="bg-[#1F232F] rounded-m overflow-hidden ">
      {/* Type */}
      <div className="w-full bg-[#252935] px-s py-s flex-row flex">
        <p className="small-content flex-1 uppercase">{prizeType}</p>
        <p className="small-content">{probability}%</p>
      </div>

      <div className="mt-s px-s">
        <p className="small-title whitespace-nowrap w-full md:w-[180px] overflow-hidden text-ellipsis">
          {name}
        </p>
      </div>
      <div className="px-s my-s">
        <div className="w-[100%] border-[0px] border-[#2F333F] bg-[#252935] rounded-m overflow-hidden">
          <img
            src={image}
            alt="prize-img"
            className="w-full mx-auto object-cover p-s rounded-m aspect-square"
          />
        </div>
      </div>
    </div>
  );
};
