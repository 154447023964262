import { ContainerV2 } from "src/components/container-v2";
import { ProfilePoints } from "src/components/profile-points";
import { RaffleV3 } from "src/components/raffle-v3";
import { RaffleV3EnergyBar } from "src/components/raffle-v3-energy-bar";
import { RaffleV3MissionList } from "src/components/raffle-v3-mission-list";
import { AirdropMultiplier } from "src/components/airdrop-multiplier";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AuthStore } from "src/stores/auth";
import { useAccount } from "wagmi";
import {
  openSuccessNotification,
  openWarningNotification,
} from "src/components";
import { useTwitterStore } from "src/stores";
import { getQueryParam } from "src/utils";
import { useAirdropStore } from "src/stores";
import { ModalConfirmUnlockReferral } from "src/components/modal-confirm-unlock-referral";

export const Airdrop = (props: any) => {
  const [openModal, setOpenModal] = useState(false);
  const [openNFTBoost, setOpenNFTBoost] = useState(false);
  const [twitterConnection, setTwitterConnection] = useState(false);
  const [profile, setProfile] = useState<any | null>({
    twitter_connected: false,
  });
  const [airdropProfile, setAirdropProfile] = useState<{} | null>(null);
  const [prizeList, setPrizeList] = useState<[]>([]);
  const [unlockReferralModal, setUnlockReferralModal] =
    useState<boolean>(false);

  const { address } = useAccount();
  const authStore = useMemo(() => new AuthStore(), []);
  const { getTwitterAuthUrl, connectTwitter } = useTwitterStore();
  const { getAirdropProfile, getPrizeList } = useAirdropStore();

  const getAirdropProfileCb = useCallback(() => {
    getAirdropProfile().then((result) => {
      if (!result) return;
      setAirdropProfile(result);
    });
  }, [getAirdropProfile, setAirdropProfile]);

  const getProfileCb = useCallback(() => {
    authStore.getProfile().then((user: any) => {
      if (!user) return;
      setProfile(user);
    });
  }, [authStore, setProfile]);

  useEffect(() => {
    const { code = "" } = getQueryParam(window.location.search);

    // doing the twitter auth
    if (code && profile._id) {
      connectTwitter({ token: code, id: profile._id }).then((result) => {
        if (result === "twitter_connect_success") {
          openSuccessNotification("Connected to Twitter");
          setTimeout(() => {
            window.history.replaceState(null, "", window.location.pathname);
            setTwitterConnection(true);
            getProfileCb();
            getAirdropProfileCb();
            return;
          }, 1000);
        }
      });
    }

    if(window.location.hash === "#task") {
          setTimeout(() => {
            const aboutPageNode = document.getElementById('task');
            if(aboutPageNode != null) {
              aboutPageNode.scrollIntoView({behavior: "smooth"});
            }
          }, 200);
    }
  }, [profile, connectTwitter, getProfileCb, getAirdropProfileCb]);

  useEffect(() => {
    getPrizeList().then((result: any) => {
      setPrizeList(result);
    });
  }, [getPrizeList]);

  useEffect(() => {
    // Fetch Profile
    if (!address) {
      setProfile({});
    }
    if (address) {
      setTimeout(() => {
        getProfileCb();
        // Fetch Airdrop Profile
        getAirdropProfileCb();
      }, 1000);
    }
  }, [address, getProfileCb, getAirdropProfileCb]);

  const handleOpenModal = () => {
    // check wallet connected first
    if (!address) {
      openWarningNotification("Please connect to Wallet");
      return;
    }

    if (!profile?.twitter_connected) {
      openSuccessNotification("Please authorize Twiiter Login");
      return;
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setOpenModal(true);
    setOpenNFTBoost(false);
  };

  const handleOpenNFTBoost = () => {
    setOpenModal(false);
    setOpenNFTBoost(true);
  };

  const handleTwitterLogin = (tryAgain = false) => {
    // check wallet connected first
    if (!address) {
      openWarningNotification("Please connect to Wallet");
      return;
    }

    if (profile?.twitter_connected && !tryAgain) {
      openSuccessNotification("Please authorize Twiiter Login");
      return;
    }

    getTwitterAuthUrl().then((url: string) => {
      window.open(url, "_self");
      return true;
    });
  };

  return (
    <ContainerV2>
      {/* Main Content */}
      <div className="my-m w-full relative">
        <div className="flex flex-row items-center justify-start mt-l">
          {/* Title */}
          <h1 className="page-title">
            SPIN & <span className="text-v2primary">EARN</span>
          </h1>
          {/* Mobile Icon */}
          <img
            src={`/assets/v3/${
              address ? "wallet-connected" : "wallet-not-connected"
            }.png`}
            alt="wallet-icon"
            className="ml-s mobile"
          />
          {/* Connect to Twitter */}
          <div className="ml-s desktop mb-[5px]">
            <button
              onClick={() => handleTwitterLogin()}
              className={`${$btnStyle} bg-[#2AABEE] text-white`}
            >
              {profile.twitter_connected ? "CONNECTED" : "CONNECT TWITTER"}
            </button>
          </div>
        </div>
        {/* Profile Points */}
        <ProfilePoints
          openModal={openModal}
          setOpenModal={setOpenModal}
          openNFTBoost={openNFTBoost}
          setOpenNFTBoost={setOpenNFTBoost}
          airdropProfile={airdropProfile}
          userId={profile._id}
          getAirdropProfileCb={getAirdropProfileCb}
          address={address}
          profile={profile}
          setUnlockReferralModal={setUnlockReferralModal}
        />

        <div className="flex mobile justify-between mt-m">
          <div className="flex-[0.6]">
            <button
              onClick={() => handleTwitterLogin()}
              className={`${$btnStyle} bg-[#2AABEE] text-white`}
            >
              {profile.twitter_connected ? "CONNECTED" : "CONNECT TWITTER"}
            </button>
          </div>
          <div className="flex-[0.4]">
            <AirdropMultiplier
              openModal={handleOpenModal}
              setOpenNFTBoost={handleOpenNFTBoost}
            />
          </div>
        </div>

        {/* Lucky Spin */}
        <RaffleV3
          airdropProfile={airdropProfile}
          userId={profile._id}
          getProfileCb={getProfileCb}
          getAirdropProfileCb={getAirdropProfileCb}
          address={address}
          profile={profile}
          prizeList={prizeList}
        />

        <RaffleV3EnergyBar airdropProfile={airdropProfile} />
        <div id="task"></div>
        <RaffleV3MissionList
          userId={profile._id}
          handleTwitterLogin={handleTwitterLogin}
          getAirdropProfileCb={getAirdropProfileCb}
          twitterConnection={twitterConnection}
          profile={profile}
          handleOpenModal={handleOpenModal}
          setUnlockReferralModal={setUnlockReferralModal}
          airdropProfile={airdropProfile}
        />
      </div>

      {/* Modals */}
      <ModalConfirmUnlockReferral
        isModalOpen={unlockReferralModal}
        setIsModalOpen={setUnlockReferralModal}
        handleTwitterLogin={handleTwitterLogin}
        profile={profile}
        address={address}
      />
    </ContainerV2>
  );
};

const $btnStyle = `
  cursor-pointer
  rounded-[5px] 2xl:rounded-[12px] 
  h-[29px] 2xl:h-[56px] 
  flex flex-row items-center 
  justify-center 
  text-[12px] 2xl:text-[20px] 
  leading-[14px] 2xl:leading-[24px] 
  blender-medium
  border-0
`;
