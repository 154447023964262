import { ProfilePolygon } from "./profile-polygon";
import { AirdropMultiplier } from "./airdrop-multiplier";
import { HoverModal } from "./hover-modal";
import { useCallback, useEffect, useRef, useState } from "react";
import { useReferralStore } from "src/stores";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
export const ProfilePoints = ({
  openModal,
  setOpenModal,
  openNFTBoost,
  setOpenNFTBoost,
  airdropProfile = {
    points: "--",
    multiplier: 100,
    totalScore: "--",
    taskCompleted: "--",
    referralCode: "",
  },
  userId = "",
  getAirdropProfileCb,
  address,
  profile,
  setUnlockReferralModal,
}: any) => {
  const [isHovered, setIsHovered] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [referralProfile, setReferralProfile] = useState({
    numOfReferrals: 0,
    canEnterReferralCode: true,
  });

  const modalRef = useRef<any>(); // Ref for the modal div

  const { getReferralProfile, doReferral } = useReferralStore();

  const getReferralProfileCb = useCallback(async () => {
    const result = await getReferralProfile();
    setReferralProfile(result);
  }, [getReferralProfile, setReferralProfile]);

  useEffect(() => {
    getReferralProfileCb();
  }, [getReferralProfileCb]);

  // Event handler to close modal if clicked outside
  const handleClickOutside = useCallback(
    (event) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        event.target.textContent !== "REFERRAL" &&
        (openModal || openNFTBoost)
      ) {
        setOpenModal(false);
        setOpenNFTBoost(false);
      }
    },
    [openModal, openNFTBoost, setOpenModal, setOpenNFTBoost]
  ); // Dependencies

  useEffect(() => {
    // Attach listener when the modal is open
    if (openModal) {
      document.addEventListener("click", handleClickOutside);
    }

    // Cleanup listener
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [openModal, openNFTBoost, handleClickOutside]); // Effect dependencies

  const handleOpenModal = () => {
    if (!address) {
      openWarningNotification("Please connect to Wallet");
      return;
    }

    if (!profile?.twitter_connected) {
      openWarningNotification("Please authorize Twitter Login");
      return;
    }

    if (!airdropProfile?.referralUnlock) {
      setUnlockReferralModal(true);
      return;
    }

    setOpenModal(!openModal);
    setIsHovered(false);
    setOpenNFTBoost(false);
  };

  const handleOpenHover = () => {
    setIsHovered(!isHovered);
    setOpenModal(false);
    setOpenNFTBoost(false);
  };

  const handleOpenNFTBoost = () => {
    setIsHovered(false);
    setOpenModal(false);
    setOpenNFTBoost(!openNFTBoost);
  };

  const handleReferralCodeInput = (value: string) => {
    setReferralCode(value);
  };

  const handleReferral = () => {
    doReferral(userId, referralCode).then((result: any) => {
      if (result === "enter_referral_code_success") {
        openSuccessNotification("Successfully entered a referral code");
        getAirdropProfileCb();
        getReferralProfileCb();
      }
    });
  };

  const handleCopyReferralLink = () => {
    navigator.clipboard.writeText(airdropProfile?.referralCode);
    openSuccessNotification("You have copied your referral code");
  };

  const copyReferralCode = () => {
    navigator.clipboard.writeText(airdropProfile.referralCode);
    openSuccessNotification("You have copied your referral code");
  };

  return (
    <div
      className="space-y-m xl:space-y-0 xl:flex xl:flex-row flex-wrap 
      xl:space-x-m mt-m xl:justify-start mb-m xl:mb-0"
    >
      <ProfilePolygon label="Points:" mainTxt={airdropProfile?.points} />
      <div className="relative">
        {isHovered && (
          <HoverModal
            showUp={isHovered}
            modalRef={modalRef}
            setIsModalOpen={setIsHovered}
          >
            <div className="flex flex-col w-full items-center">
              <p className="hover_title">Referral</p>
              <p className="hover_content">
                Boost your multiplier by referring friends!
              </p>

              {/* <p className="hover_title">invite count</p>
              <p className="hover_content">Permanent</p>

              <p className="hover_title">lucky spin</p>
              <p className="hover_content"> Permanent</p> */}
            </div>
          </HoverModal>
        )}

        {openModal && (
          <HoverModal
            showUp={openModal}
            modalRef={modalRef}
            setIsModalOpen={setOpenModal}
          >
            <div className="flex flex-col w-full items-center">
              <p className="hover_title text-[24px]">invited:</p>
              <p className="hover_content !text-[48px] !leading-[57px]">
                {referralProfile?.numOfReferrals || 0}/50
              </p>
              <p
                onClick={() => handleCopyReferralLink()}
                className="hover_content mt-[10px] !text-[16px] text-center !leading-[19px] underline cursor-pointer"
              >
                CLICK TO COPY CODE
              </p>
              <p className="hover_content text-center !text-[10px] mt-[10px] !leading-[12px] px-[15px]">
                (Only invitees who connected to wallet and twitter account will
                be counted)
              </p>

              <p className="mt-m text-center">
                Your Referral Code:{" "}
                <span
                  onClick={() => copyReferralCode()}
                  className="underline cursor-pointer"
                >
                  {airdropProfile.referralCode}
                </span>
              </p>

              {referralProfile?.canEnterReferralCode && (
                <>
                  <p className="mt-m">Enter Referral Code:</p>
                  <input
                    type="text"
                    value={referralCode}
                    onChange={(e) => handleReferralCodeInput(e.target.value)}
                    className="p-1 rounded-[4px] mt-1"
                  />

                  <button className="mt-m" onClick={handleReferral}>
                    Enter Referral
                  </button>
                </>
              )}

              {!referralProfile?.canEnterReferralCode && (
                <>
                  <p className="hover_content text-center !text-[10px] mt-[10px] !leading-[12px] px-[15px]">
                    You already have entered a referral code
                  </p>
                </>
              )}
            </div>
          </HoverModal>
        )}

        {openNFTBoost && (
          <HoverModal
            showUp={openNFTBoost}
            modalRef={modalRef}
            setIsModalOpen={setOpenNFTBoost}
          >
            <div className="flex flex-row w-full items-center justify-center h-full">
              <p className="hover_title">Coming Soon</p>

              {/* <div className="flex flex-1 flex-col items-center">
                <img
                  className="h-[128px]"
                  src="/assets/v3/nftBoost-ring.png"
                  alt="nft boost"
                />
                <p className="hover_content px-[20px] !text-[12px] text-center !leading-[14px] underline">
                  get Championship Ring nft through activities or companionship
                </p>
              </div>
              <div className="flex flex-1 flex-col items-center">
                <img
                  className="h-[128px]"
                  src="/assets/v3/nftBoost-ticket.png"
                  alt="ticket"
                />
                <p className="hover_content px-[20px] !text-[12px] text-center !leading-[14px] underline">
                  buy & hold geda pass nft to get extra boost for Permanent{" "}
                </p>
              </div> */}
            </div>
          </HoverModal>
        )}

        <ProfilePolygon
          label="Multiplier:"
          mainTxt={`${airdropProfile?.multiplier || 100}%`}
          mainTxtSx={{
            textDecoration: "underline",
            textDecorationThickness: "1px",
            textUnderlineOffset: "10px",
            cursor: "pointer",
          }}
          extraRender={
            <AirdropMultiplier
              openModal={handleOpenModal}
              setOpenNFTBoost={handleOpenNFTBoost}
            />
          }
          width={530}
          bgImg={"poly-btn-long.png"}
          gedaPos={{ right: 120 }}
          setIsHovered={setIsHovered}
          handleOnClick={handleOpenHover}
        />
      </div>

      <ProfilePolygon
        label="total score (season 1):"
        mainTxt={airdropProfile?.totalScore}
      />
      <ProfilePolygon
        label="Matrix Box Key"
        mainTxt={profile?.matrix_key || 0}
        backgroundColor="#FE5219"
        iconBg="FIRE.png"
        gedaPos={{
          right: 20,
          filter: "grayscale(1) contrast(100%)",
          transform: "scale(120%)",
          opacity: 0.7,
        }}
      />
    </div>
  );
};
