const $btnStyle = `
  cursor-pointer
  w-[85px] 2xl:w-[125px] 
  rounded-[5px] 2xl:rounded-[12px] 
  h-[29px] 2xl:h-[56px] 
  flex flex-row items-center 
  justify-center 
  text-[12px] 2xl:text-[20px] 
  leading-[14px] 2xl:leading-[24px] 
  blender-medium
  border-0
`;

export const AirdropMultiplier = (props: any) => {
  const { openModal, setOpenNFTBoost } = props;

  return (
    <div className="flex flex-row items-center justify-end relative z-[10] space-x-s ml-m">
      <p
        onClick={() => setOpenNFTBoost && setOpenNFTBoost()}
        className={`${$btnStyle} bg-v2primary text-white`}
      >
        NFT BOOST
      </p>
      <p
        onClick={() => openModal && openModal()}
        className={`${$btnStyle} bg-white text-black`}
      >
        REFERRAL
      </p>
    </div>
  );
};
