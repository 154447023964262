import { ContainerV2 } from "src/components/container-v2";

export const AboutUs = () => {
  return (
    <ContainerV2>
      <div className="flex flex-col gap-[100px] p-space-5 py-space-10 pt-[100px]">
        {/* Title */}
        <div className="flex flex-col gap-space-5">
          <h1 className="page-title text-center">公司簡介</h1>
          <div className="flex flex-col md:flex-row gap-space-5 relative">
            <p className="min-w-[400px] max-w-[600px] text-center mx-auto">
              GEDA Limited 是一家專注於電子競技和 Web3
              遊戲的創新公司。作為行業先驅，GEDA Limited
              將區塊鏈技術引入電子競技和遊戲生態系統，致力於提升玩家的參與度和互動性。我們的目標是讓電子競技對所有人都更為可及，並透過
              Web3 平台推動遊戲的未來發展。
            </p>
          </div>
        </div>

        <video
          autoPlay
          muted={true}
          playsInline
          loop
          className="relative object-fit z-[1] h-[150px] rounded-xs"
        >
          <source src={`/assets/v3/GEDA_Platform.mp4`} type="video/mp4" />
        </video>

        <div className="flex flex-col gap-space-5 ">
          <h1 className="page-subtitle text-center">核心業務</h1>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-space-5">
            <div className="flex flex-col gap-space-5 relative items-center ">
              <h3 className="relative z-[1]">
                <div className="rounded-full bg-orange-600 w-4 h-4 absolute top-[-6px] left-[-5px]" />
                <span className="relative z-[1]">電子競技賽事</span>
              </h3>
              <img
                src="https://images.pexels.com/photos/9072388/pexels-photo-9072388.jpeg?auto=compress&cs=tinysrgb&w=600"
                alt="img"
                className="rounded-xs w-full md:w-[60%] h-[200px] object-cover"
              />
              <p className="max-w-[300px] text-center">
                我們組織和舉辦全球各地的大型電子競技賽事，提供給玩家一個展示和競技的舞台。
              </p>
            </div>

            <div className="flex flex-col gap-space-5 relative  items-center">
              <h3 className="relative z-[1]">
                <div className="rounded-full bg-orange-600 w-4 h-4 absolute top-[-6px] left-[-5px]" />
                <span className="relative z-[1]">Web3 遊戲平台</span>
              </h3>
              <img
                src="https://images.pexels.com/photos/459762/pexels-photo-459762.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="img"
                className="rounded-xs w-full md:w-[60%] h-[200px] object-cover"
              />
              <p className="max-w-[300px] text-center">
                GEDA
                提供一個完整的遊戲生態系統，讓玩家能夠透過我們的平台進行遊戲資產的互操作和交易。
              </p>
            </div>

            <div className="flex flex-col gap-space-5 relative items-center">
              <h3 className="relative z-[1]">
                <div className="rounded-full bg-orange-600 w-4 h-4 absolute top-[-6px] left-[-5px]" />
                <span className="relative z-[1]">創新獎勵系統</span>
              </h3>
              <img
                src="https://images.pexels.com/photos/7915492/pexels-photo-7915492.jpeg?auto=compress&cs=tinysrgb&w=600"
                alt="img "
                className="rounded-xs w-full md:w-[60%] h-[200px] object-cover"
              />
              <p className="max-w-[300px] text-center">
                通過我們獨特的預測遊戲和任務系統，玩家能夠獲得各種獎勵，增強遊戲體驗。
              </p>
            </div>
          </div>
        </div>

        {/* Our Vision */}
        <div className="flex flex-col gap-space-5">
          <h1 className="page-subtitle text-center">願景與使命</h1>
          <p className="text-center max-w-[500px] mx-auto">
            我們相信 Web3 電子競技將成為未來遊戲產業的核心動力。GEDA Limited
            將依托於我們Cyber Games
            Arena團隊在專業電子競技領域超過十年的經驗，推動電子競技和 Web3
            遊戲的融合與發展。我們致力於通過創新的技術和平台，為全球遊戲社群創造獨特且令人興奮的體驗。
          </p>
        </div>

        <img
          src="https://images.pexels.com/photos/7915357/pexels-photo-7915357.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          alt="img"
          className="rounded-xs w-[100%] h-[200px] object-cover"
        />

        {/* About Us */}
        <div className="flex flex-col gap-space-5">
          <h1 className="page-subtitle text-center">關於我們</h1>
          <p className="text-center max-w-[500px] mx-auto">
            GEDA Limited 由一群富有創新精神的 Web3
            專家、前職業電子競技運動員、及 Cyber Games Arena
            團隊組成，結合了豐富的行業經驗和對未來技術的深刻理解。我們致力於推動遊戲產業的變革，並期待與您一同創造遊戲的新未來。
          </p>
        </div>
      </div>
    </ContainerV2>
  );
};
