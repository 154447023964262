import { useEffect } from "react";
import { useAccount, useNetwork } from "wagmi";
// import * as Configs from "../config";
import { useAuthStore } from "src/stores";
import { AuthStore } from "src/stores/auth";

export const Auth = (props: any) => {
  const { children } = props;

  const authStore = new AuthStore();
  const { address } = useAccount();
  const { chain } = useNetwork();
  // const { switchNetwork } = useSwitchNetwork();
  const { access } = useAuthStore();

  useEffect(() => {
    if (!address) {
      return authStore.logout();
    }
    if (!chain) return;

    // Check chain
    // if (Configs.NETWORK === "testnet") {
    //   if (chain?.id !== 80001) {
    //     // Change to 5 in the future
    //     switchNetwork?.(80001);
    //   }
    // }
    // if (Configs.NETWORK === "mainet") {
    //   if (chain?.id !== 80001) {
    //     switchNetwork?.(80001);
    //   }
    // }

    // Getting Address information
    access({
      address: address,
    });
    // eslint-disable-next-line
  }, [address, chain, access]);

  return <>{children}</>;
};
