import { useEffect, useState } from "react";

export const Countdown = (props: any) => {
  const { time } = props;

  const [timeLeft, setTimeLeft] = useState<any>(time);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(time);
    }, 1000);

    return () => clearTimeout(timer);
  });

  let timerComponents: any = [];

  const getUnits = (interval: any) => {
    if (interval === "D") return "d ";
    if (interval === "H") return "h ";
    if (interval === "Mins") return "m ";
  };

  if (timeLeft === 0) {
    return (timerComponents = <span>Ended</span>);
  }

  Object.keys(timeLeft).forEach((interval, i) => {
    let time = timeLeft[interval];
    if (time < 10) time = `0${time}`;

    timerComponents.push(
      <span key={i}>
        {time}
        {getUnits(interval)}{" "}
      </span>
    );
  });

  if (!time) return null;

  return <span>{timerComponents}</span>;
};
