export const FooterV2 = (props: any) => {
  return (
    <div
      className="relative w-full flex flex-row items-center justify-center"
      style={{ background: "transparent", borderColor: "transparent" }}
    >
      <div className="flex-1">
        <p className="text-[12px] 2xl:text-[18px] blender-medium w-[315px] 2xl:w-[470px] uppercase desktop">
          Blockchain-powered esports infrastructure: a revolutionary model
          allowing everyone to experience and engage in esports.
        </p>

        <p className="text-[16px] leading-[18px] blender-medium w-[180] uppercase mobile">
          ONBOARDING BILLIONS <br /> OF ESPORTS ENTHUSIASTS TO WEB3
        </p>
      </div>

      <div className="desktop flex-1 flex flex-row items-center justify-center space-x-[28px]">
        <img
          src="/assets/v3/discord.png"
          alt="discord"
          className={$footerIconStyle}
          onClick={() => {
            window.open("https://discord.gg/gedaesports", "_target");
          }}
        />
        <img
          src="/assets/v3/twitter.png"
          alt="twitter"
          className={$footerIconStyle}
          onClick={() => {
            window.open("https://twitter.com/GEDAEsports", "_target");
          }}
        />
        <img
          src="/assets/v3/youtube.png"
          alt="youtube"
          className={$footerIconStyle}
          onClick={() => {
            window.open("https://www.youtube.com/@GeDaEsports", "_target");
          }}
        />
        <img
          src="/assets/v3/twitch.png"
          alt="twitch"
          className={$footerIconStyle}
          onClick={() => {
            window.open("https://twitch.tv/gedaesports", "_target");
          }}
        />
        {/* <img
          src="/assets/v3/instagram.png"
          alt="instagram"
          className={$footerIconStyle}
        />
        <img
          src="/assets/v3/tik_tok.png"
          alt="tik_tok"
          className={$footerIconStyle}
        /> */}
      </div>

      <div className="flex-1 desktop"></div>
    </div>
  );
};

const $footerIconStyle =
  "cursor-pointer w-[32px] 2xl:w-[48px] h-[32px] 2xl:h-[48px]";
