import { LoadingIcon } from "../components";
import { useCallback, useEffect, useState } from "react";
import { Auth } from "src/components/auth";
import { useAccount } from "wagmi";
import { AuthStore } from "src/stores/auth";
import { useWeb3Modal } from "@web3modal/react";
import { history, useAuthStore } from "src/stores";
import { MyBagItem } from "src/components/my-bag";
import { ContainerV2 } from "src/components/container-v2";

export const MyBag = (props: any) => {
  const [allLoaded, setAllLoaded] = useState<boolean>(false);

  const [profile, setProfile] = useState<{} | null>(null);
  const [prizes, setPrizes] = useState<[] | null>(null);

  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const { getMyBag } = useAuthStore();
  const authStore = new AuthStore();

  const getMyBagCb = useCallback(() => {
    getMyBag().then((list) => {
      if (!list) return;
      setPrizes(list);
    });
  }, [getMyBag]);

  useEffect(() => {
    // Fetch Profile
    if (!address) {
      setProfile({});
      setPrizes([]);
    }
    if (address) {
      setTimeout(() => {
        authStore.getProfile().then((user: any) => {
          if (!user) return;
          setProfile(user);
        });

        getMyBagCb();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [address, getMyBagCb]);

  useEffect(() => {
    if (!profile) return;
    if (!prizes) return;

    setAllLoaded(true);
  }, [profile, prizes]);

  return (
    <Auth>
      <ContainerV2>
        <div className="flex flex-row overflow-hidden">
          {/* Main Content */}
          <div className="py-m w-full overflow-y-scroll h-screen overflow-y-scroll">
            {!allLoaded && (
              <div className="w-full h-full flex flex-1 justify-center items-center relative z-[15]">
                <LoadingIcon />
              </div>
            )}

            {allLoaded && !address && (
              <div className={$btnWrapper}>
                <button className={$btnStyle} onClick={() => open()}>
                  {"Connect Wallet"}
                </button>
              </div>
            )}

            {allLoaded && address && prizes && prizes.length === 0 && (
              <div className={$btnWrapper}>
                <button className={$btnStyle} onClick={() => history.push("/")}>
                  {"Play Game"}
                </button>
              </div>
            )}

            {allLoaded && address && prizes && prizes?.length > 0 && (
              <div className="relative z-[15]">
                <h1 className="page-title">My Bag</h1>
                <div className="mt-m grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 2xl:grid-cols-7 gap-[10px]">
                  {prizes?.map((item, i) => {
                    return (
                      <MyBagItem key={i} item={item} getMyBagCb={getMyBagCb} />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </ContainerV2>
    </Auth>
  );
};

const $btnWrapper =
  "flex flex-1 justify-center items-center relative z-[15] h-full";
const $btnStyle =
  "bg-gradient-to-r from-[#F99433] to-[#FE5218] text-white  px-[20px] py-[10px] border-[0px] mx-auto w-[250px] justify-center flex";
