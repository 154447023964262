import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Container } from "./container";
import { MobileNav } from "./mobile-nav";
import { NavV2 } from "./nav-v2";
import { Auth } from "./auth";

export const ContainerV2 = (props: any) => {
  const { children } = props;
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <Auth>
      <Container invisibleHeader={true} showOldNav={false}>
        <div
          className={`bg-black min-h-screen w-full py-m md:py-[20px] px-m md:px-[40px] pb-m md:pb-[40px]`}
          style={{
            overflow: openDrawer ? "hidden" : "auto",
          }}
        >
          <div className="max-w-full min-w-full lg:max-w-[1620px] lg:min-w-[1024px] w-full m-auto relative">
            <NavV2 />
            <MobileNav setOpenDrawer={setOpenDrawer} openDrawer={openDrawer} />

            {children}
          </div>
        </div>
      </Container>
    </Auth>
  );
};
