import { history } from "src/stores";
import { openWarningNotification } from "src/components";
import { responseMsg } from "src/types/responseState";
import { formatMessage } from "./format";

export const handleNotEnoughBalance = () => {
  openWarningNotification(formatMessage(responseMsg.NotEnoughBalance));
  history.push("/dashboard", { isBalanceInsufficient: true });

  return false;
};
