import { useWindowSize } from "@uidotdev/usehooks";
import { useMemo } from "react";

export const RaffleV3EnergyBar = (props: any) => {
  const { airdropProfile } = props;
  const size: any = useWindowSize();

  const percentage = airdropProfile?.energy || 0;

  const formattedPerc = useMemo(() => {
    if (percentage === 0) return 0;
    return Math.round(percentage / 5) * 5;
  }, [percentage]);

  return (
    <div className="flex flex-col mt-10">
      <div className="flex flex-row w-full items-center">
        <h1 className="page-subtitle w-[100%]">ENERGY BAR</h1>
        <div className="flex flex-col xl:flex-row justify-end w-full items-end xl:items-center">
          <p className="flex-1 xl:text-end content !leading-[10px] xl:leading-[17px]">
            When the energy bar is full{size?.width < 1080 ? "" : ","}
          </p>
          <p className="xl:ml-1 xl:text-end content text-v2primary !leading-[10px] xl:leading-[17px]">
            you'll earn an extra 100% of your current total score
          </p>
        </div>

        <img
          className="w-[23px] h-[23px] xl:w-[23px] xl:h-[23px]"
          src="/assets/v3/geda.png"
          alt="energy"
        />
      </div>
      <img src={`/assets/v3/${formattedPerc}.png`} alt="energy_bar" />
      <div className="flex flex-row w-full items-center mt-4 relative">
        <p className="flex-1 content !text-[16px] xl:!text-[21px] 2xl:!text-[32px] !leading-[10px]">
          0%
        </p>
        {/* Inline style for dynamic positioning */}
        {percentage >= 5 && (
          <p
            className="flex-1 content !text-[16px] xl:!text-[21px] xl:!text-[32px] !leading-[10px]"
            style={{
              position: "absolute",
              left: Math.min(percentage, 90) + "%",
              transform: "translateX(-50%)",
            }}
          >
            {percentage}%
          </p>
        )}

        <p className="flex-1 text-end content !text-[16px] xl:!text-[32px] !leading-[10px]">
          100%
        </p>
      </div>
    </div>
  );
};
