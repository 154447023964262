import { Modal } from "antd";
import { CloseButton } from "./close-btn";
import { openWarningNotification } from "./notification";
import { useMemo } from "react";
export const ModalConfirmTweet = (props: any) => {
  const {
    isModalOpen,
    setIsModalOpen,
    doTwitterTask,
    handleTwitterErr,
    twitterJwt,
    twitterName,
    twitterType,
    selectedTask,
    getMissionListCb,
    userId,
  } = props;
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = async () => {
    if (selectedTask?.completed) {
      openWarningNotification(
        "You have done this task - " + twitterName.toUpperCase()
      );
      return;
    }
    try {
      const result = await doTwitterTask(
        twitterJwt,
        twitterType,
        twitterName,
        selectedTask
      );
      if (result === "unknown_error") {
        return handleTwitterErr();
      }
      getMissionListCb(userId);
      viewTweet();
      handleCancel();
    } catch (e) {
      await handleTwitterErr();
      return;
    }
  };

  const viewTweet = () => {
    window.open(
      `https://twitter.com/GEDAEsports/status/${selectedTask?.target}`,
      "_blank"
    );
  };

  const name = useMemo(() => {
    if (twitterType === "rt_tweet") return "Retweet";
    if (twitterType === "like_tweet") return "Like";
    return "";
  }, [twitterType]);
  return (
    <Modal
      title={``}
      visible={isModalOpen}
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={null}
      centered={true}
      bodyStyle={{
        backgroundColor: "black",
        borderRadius: 10,
      }}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleCancel} />

      <div className="flex flex-col justify-center items-center min-h-[200px]">
        {/* Title */}
        <h3 className="card-title">Confirm Tweet</h3>
        {/* Confirmation */}
        <div className="mt-m">
          <p className={`content text-white text-center`}>
            Do you want to {name} this tweet ?
          </p>
          {/* Confirm Button */}
          <div className="mt-m">
            <button
              className="bg-gradient-to-r from-[#F99433] to-[#FE5218] text-white  px-[20px] py-[10px] border-[0px] mx-auto w-full md:w-[300px] justify-center flex blender-medium"
              onClick={() => handleConfirm()}
            >
              CONFIRM
            </button>
          </div>

          <div className="mt-s">
            <p
              className={`content text-white cursor-pointer text-center uppercase`}
              onClick={() => viewTweet()}
            >
              View Tweet
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};
