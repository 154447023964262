import { useEffect, useState } from "react";

export const ProfilePolygon = (props: any) => {
  const {
    // width = null,
    height = "149px",
    label = "label",
    mainTxt = "0",
    mainTxtSx = {},
    gedaPos = { right: 20 },
    extraRender = null,
    setIsHovered = null,
    handleMouseMove = null,
    profilePolygonRef = null,
    handleOnClick = null,
    backgroundColor = "#1D1D1d",
    iconBg = "geda-bg.png",
    // bgImg = null,
  } = props;

  const [widowWidth, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = widowWidth <= 1080;

  return (
    <div
      className={`w-[183.4px] h-[83px] 
      py-[20px] px-[20px] 2xl:p-m relative overflow-hidden 
      flex flex-row flex-1
      items-center justify-start 2xl:mb-[0px]
      2xl:!min-w-[329px] 2xl:!h-[149px]`}
      style={{
        backgroundSize: "cover",
        maxHeight: height,
        width: "fit-content",
        backgroundImage: "none",
        backgroundColor: backgroundColor,
        borderRadius: 16,
        height: "auto",
        minWidth: 200,
        ...(isMobile && {
          width: "100%",
        }),
      }}
    >
      <div className="flex flex-col">
        <p className={$label}>{label}</p>
        <p
          className={$mainTxt}
          style={{
            ...mainTxtSx,
            ...(isMobile &&
              mainTxtSx.textUnderlineOffset && {
                textUnderlineOffset: 5,
              }),
          }}
          onMouseEnter={() => setIsHovered && setIsHovered(true)}
          // Event handler for mouse leaving the element
          onMouseLeave={() => setIsHovered && setIsHovered(false)}
          onMouseMove={handleMouseMove && handleMouseMove}
          ref={profilePolygonRef}
          onClick={() => handleOnClick && handleOnClick()}
        >
          {mainTxt}
        </p>
      </div>

      <div className="desktop">{extraRender}</div>

      <img
        src={`/assets/v3/${iconBg}`}
        alt="logo-bg"
        className="absolute h-full top-0 max-2xl:!right-[20px]"
        style={{ ...gedaPos }}
      />
    </div>
  );
};

const $label =
  "blender-medium !font-[700] text-[13px] 2xl:text-[24px] leading-[16px] 2xl:leading-[29px] uppercase";
const $mainTxt =
  "blender-medium !font-[700] text-[36px] 2xl:text-[64px] leading-[43px] 2xl:leading-[77px]";
