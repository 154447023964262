export const getEarnPrizeItemRenderParameterSet = (prizeItem: any, prizeItemList: any[], prizeItemIndex: number = 0) => {
  let [prizeType, mainTxt] = prizeItem?.type.split("_");
  let iconImg = "/assets/v3/gega-icon.png";
  let showMainTxt = true;
  let showImg = true;
  let bgImg = null;

  switch (prizeType) {
    case "energy":
      iconImg = "/assets/v3/flash-icon.png";
      mainTxt += "%";
      break;

    case "multiplier":
      mainTxt += "%";
      break;
  }

  let bgColor = "#1D1D1D";

  if (prizeItemIndex > 0 && prizeItemList[prizeItemIndex].id === prizeItem?.id) {
    bgColor = "#FFFFFF40";
  }

  if (prizeType === "fire") {
    bgColor = "#FE5219";
    iconImg = "/assets/v3/FIRE.png";
  }

  if (prizeType === "nyannft") {
    showMainTxt = false;
    showImg = false;
    bgColor = "transparent";
    bgImg = "/assets/games/nyan.png";
  }

  if (prizeType === "guardiannft") {
    showMainTxt = false;
    showImg = false;
    bgColor = "transparent";
    bgImg = "/assets/games/nyan_alt.png";
  }

  if (prizeType === "mixmobnft") {
    showMainTxt = false;
    showImg = false;
    bgColor = "#ff27ee";
    bgImg = "/assets/games/mixmob.png";
  }

  if (prizeType === "pomeriumnft") {
    showMainTxt = false;
    showImg = false;
    bgColor = "transparent";
    bgImg =
      "https://i.seadn.io/s/raw/files/7401eca2d039cdd0f2549542afab569f.png?auto=format&dpr=1&w=384";
  }

  if (prizeType === "pomeriumtoken") {
    showMainTxt = false;
    showImg = true;
    bgColor = "#ffffff";
    iconImg = "https://pomerium.space/assets/pmg-icon1-f0393b95.gif";
  }

  if (prizeType === "iagentnode") {
    showMainTxt = false;
    showImg = false;
    bgColor = "transparent";
    bgImg =
      "https://pbs.twimg.com/profile_images/1755138261507534848/ze2Py5L__400x400.jpg";
  }

  if (prizeType === "kukubox") {
    showMainTxt = false;
    showImg = false;
    bgColor = "transparent";
    bgImg = "/assets/games/kukubox.jpeg";
  }

  if (prizeType === "firebox") {
    showMainTxt = false;
    showImg = false;
    bgColor = "transparent";
    bgImg = "/assets/games/firebox.jpeg";
  }

  if (prizeType.indexOf("usdt") > -1) {
    showMainTxt = false;
    showImg = false;
    bgColor = "transparent";
    bgImg = "/assets/games/usdt_img.jpeg";
  }

  if (prizeType === "ssnft") {
    showMainTxt = false;
    showImg = false;
    bgColor = "transparent";
    bgImg = "/assets/games/ssnft.gif";
  }

  if (prizeType === "cellulanft") {
    showMainTxt = false;
    showImg = false;
    bgColor = "transparent";
    bgImg =
      "https://pbs.twimg.com/profile_images/1775784317421318144/PUDhpeaM_400x400.jpg";
  }

  if (prizeType === "bsdwl") {
    showMainTxt = false;
    showImg = false;
    bgColor = "transparent";
    bgImg =
      "https://pbs.twimg.com/profile_images/1760335903497580544/8t4B7SGH_400x400.jpg";
  }

  return {
    bgColor: bgColor,
    bgImg: bgImg,
    iconImg: iconImg,
    mainTxt: mainTxt,
    prizeType: prizeType,
    showImg: showImg,
    showMainTxt: showMainTxt
  };
};

export const getModalEarnPrizeBackgroundImage = (type: string) => {
  if (type) {
    let bgImg = "/assets/v3/gega-icon.png";
    let prizeType = "";
    [prizeType] = type.split("_");

    switch (prizeType) {
      case "energy":
      case "multiplier":
        return "/assets/v3/flash-icon.png";
      case "fire":
        return "/assets/v3/FIRE.png";
      case "nyannft":
        return "/assets/games/nyan.png";
      case "guardiannft":
        return "/assets/games/nyan_alt.png";
      case "mixmobnft":
        return "/assets/games/mixmob.png";
      case "pomeriumnft":
        return "https://i.seadn.io/s/raw/files/7401eca2d039cdd0f2549542afab569f.png?auto=format&dpr=1&w=384";
      case "pomeriumtoken":
        return "https://pomerium.space/assets/pmg-icon1-f0393b95.gif";
      case "iagentnode":
        return "https://pbs.twimg.com/profile_images/1755138261507534848/ze2Py5L__400x400.jpg";
      case "firebox":
        return "/assets/games/firebox.jpeg";
      case "kukubox":
        return "/assets/games/kukubox.jpeg";
      case "ssnft":
        return "/assets/games/ssnft.gif";
      case "cellulanft":
        return "https://pbs.twimg.com/profile_images/1775784317421318144/PUDhpeaM_400x400.jpg";
      case "bsdwl":
        return "https://pbs.twimg.com/profile_images/1760335903497580544/8t4B7SGH_400x400.jpg";
      default:
        if (prizeType.indexOf("usdt") > -1) {
          return "/assets/games/usdt_img.jpeg";
        }
        return bgImg;
    }
  }
};
