import { Modal } from "antd";
import { CloseButton } from "./close-btn";
import { RaffePrize } from "./rafflePrize";

import { getModalEarnPrizeBackgroundImage } from "src/utils/earn";

export const ModalAirdropRafflePrizeList = (props: any) => {
  const { isModalOpen, setIsModalOpen, formattedPrizeList } = props;

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={``}
      visible={isModalOpen}
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={null}
      centered={true}
      bodyStyle={{
        backgroundColor: "black",
        borderRadius: 10,
        height: "80vh",
        overflow: "hidden",
        overflowY: "scroll",
      }}
      width={"80vw"}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleCancel} />

      <div className="flex flex-col justify-center items-center min-h-[200px]">
        {/* Title */}
        <h5 className="font-[400] text-white text-[20px] uppercase">
          PRIZES & POSSIBILITIES
        </h5>
        {/* Confirmation */}
        <div className="relative z-[15] px-s md:px-m">
          <div className="mt-m grid grid-cols-2 sm:grid-cols-3 md:grid-cols-7 2xl:grid-cols-6 gap-[10px]">
            {formattedPrizeList.map((prize: any, i: number) => {
              const image = getModalEarnPrizeBackgroundImage(prize?.type);
              return <RaffePrize prize={prize} key={i} image={image} />;
            })}
          </div>
        </div>
        {/* Confirm Button */}
        <div className="mt-m">
          <button
            className="bg-gradient-to-r from-[#F99433] to-[#FE5218] text-white  px-[20px] py-[10px] border-[0px] mx-auto w-full md:w-[300px] justify-center flex"
            onClick={() => handleCancel()}
          >
            CLOSE
          </button>
        </div>
      </div>
    </Modal>
  );
};
