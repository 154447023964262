import React from "react";
import { Provider } from "mobx-react";
import Routers from "./router";
import { stores, StoresContext } from "./stores";
import { Web3Modal } from "@web3modal/react";
import { WagmiConfig } from "wagmi";
import {
  ethereumClient,
  // GOOGLE_CLIENT_ID,
  projectId,
  wagmiConfig,
} from "./config";
// import { AuthProvider } from "./components/auth-context";
// import { IProfile } from "./types/user";
// import { GoogleOAuthProvider } from "@react-oauth/google";
// import { WebSocketProvider } from "./components/websocket-context";

function App() {
  // const [loggedIn, setLoggedIn] = useState(false);
  // const [userInfo, setUserInfo] = useState<IProfile | null>(null);
  // const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);

  return (
    <>
      <Provider {...stores}>
        <StoresContext.Provider value={stores}>
          {/* <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}> */}
          <WagmiConfig config={wagmiConfig}>
            {/* <AuthProvider
                loggedIn={loggedIn}
                userInfo={userInfo}
                setLoggedIn={setLoggedIn}
                setUserInfo={setUserInfo}
                openLoginModal={openLoginModal}
                setOpenLoginModal={setOpenLoginModal}
              > */}
            {/* <WebSocketProvider> */}
            <Routers />
            {/* </WebSocketProvider> */}
            {/* </AuthProvider> */}
          </WagmiConfig>
          {/* </GoogleOAuthProvider> */}
        </StoresContext.Provider>
      </Provider>

      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        themeMode={"light"}
        themeVariables={{
          "--w3m-z-index": "9999",
        }}
      />
    </>
  );
}

export default App;
