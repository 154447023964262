export const getRandomIndexByPropertyValue = (
  array: any,
  property: any,
  value: any
) => {
  // Filter the array to include only objects with the specified property value
  const filteredArray = array.filter((item: any) => item[property] === value);

  // Check if there are any matching items
  if (filteredArray.length === 0) {
    return -1; // No matching items found
  }

  // Generate a random index within the length of the filtered array
  const randomIndex = Math.floor(Math.random() * filteredArray.length);

  // Return the index within the original array
  return array.indexOf(filteredArray[randomIndex]);
};

export const makeid = (length: number) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
