import { ContainerV2 } from "src/components/container-v2";
// import { PredictionV3AllList } from "src/components/prediction-v3-all-list";
import { PredictionV3LiveList } from "src/components/prediction-v3-live-list";
import { useWindowSize } from "@uidotdev/usehooks";

export const PredictionV3 = (props: any) => {
  const size: any = useWindowSize();

  return (
    <ContainerV2>
      <div className="flex flex-col pb-m overflow-hidden mt-l">
        {/* all prediction list */}
        {/* {size.width > 1080 && <PredictionV3AllList />} */}

        {/* live prediction */}
        <PredictionV3LiveList isMobile={size.width < 1080} />
      </div>
    </ContainerV2>
  );
};
