import moment from "moment";
import { Countdown } from "./countdown";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGameStore } from "src/stores";
import { ModalConfirmPrediction } from "./modal-confirm-prediction";
import { useWeb3Modal } from "@web3modal/react";
import { useWindowSize } from "@uidotdev/usehooks";
import { isMobile } from "react-device-detect";

export const GamePredictionRow = (props: any) => {
  const { game, address, fetchGame } = props;
  const {
    predict_by = null,
    team_a = "Team A",
    team_a_logo = "/assets/GeDa_default.png",
    team_b = "Team B",
    team_b_logo = "/assets/GeDa_default.png",
    result,
    extra,
  } = game;

  const [isConfirmed] = useState<boolean>(false);
  const [isPicked, setIsPicked] = useState<string | null>(null); // team_a / team_b
  const [myPick, setMyPick] = useState<any | null>(null);
  const [analysis, setAnalysis] = useState<any>({});
  const [openPredictionModal, setOpenPredictionModal] =
    useState<boolean>(false);

  const { open } = useWeb3Modal();
  const { getMyPick, getPredictionAnalysis } = useGameStore();
  const size: any = useWindowSize();

  const isResponsive = isMobile || size?.width < 800;

  const getMyPickCb = useCallback(() => {
    getMyPick(game["_id"]).then((result) => {
      if (!result) return;
      setIsPicked(result?.predict_team);
      setMyPick(result);
    });
  }, [game, getMyPick]);

  useEffect(() => {
    getPredictionAnalysis(game?._id).then((result: any) => {
      if (!result) return;
      setAnalysis(result);
    });
  }, [getPredictionAnalysis, game]);

  useEffect(() => {
    if (!address) return;
    getMyPickCb();
  }, [address, getMyPickCb]);

  const teamASupportsPerc = useMemo(() => {
    if (analysis?.team_a_supporters === 0 && analysis?.team_b_supporters === 0)
      return 0;
    return (
      (analysis?.team_a_supporters /
        (analysis?.team_a_supporters + analysis?.team_b_supporters)) *
      100
    ).toFixed(2);
  }, [analysis]);

  const teamBSupportsPerc = useMemo(() => {
    if (analysis?.team_a_supporters === 0 && analysis?.team_b_supporters === 0)
      return 0;
    return (
      (analysis?.team_b_supporters /
        (analysis?.team_a_supporters + analysis?.team_b_supporters)) *
      100
    ).toFixed(2);
  }, [analysis]);

  const calculateTimeLeft = () => {
    let difference = +new Date(predict_by) - +new Date();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        D: Math.floor(difference / (1000 * 60 * 60 * 24)),
        H: Math.floor((difference / (1000 * 60 * 60)) % 24),
        Mins: Math.floor((difference / 1000 / 60) % 60),
        // Secs: Math.floor((difference / 1000) % 60),
      };
    }

    if (difference <= 0) {
      // refresh();
      return 0;
    }
    return timeLeft;
  };

  const confirmModal = () => {
    if (!address) {
      return open();
    }
    setOpenPredictionModal(true);
  };

  const selectTeam = (team: string) => {
    if (isConfirmed) return;
    setIsPicked(team);
  };

  // if (!game_time) return null;
  if (!predict_by) return null;

  return (
    <div className="flex flex-col w-full mt-[10px]">
      {/* Time */}
      <div className="flex flex-col md:flex-row items-start md:items-end h-[auto] md:h-[35px]">
        <p className="min-w-[120px] small-content">
          {game?.game_time
            ? moment.utc(game?.game_time).format("dddd, DD MMMM")
            : "TBC"}
        </p>
        <div className="flex flex-row w-full">
          <div className="flex-1">
            {!result && (
              <p className="mt-[0px] md:mt-[0px] flex flex-row small-content">
                <span className="mr-[5px]">Prediction ends in</span>
                <Countdown time={calculateTimeLeft()} />
              </p>
            )}

            {result && (
              <div className="flex flex-col md:flex-row">
                <p className="small-content">
                  Result:{" "}
                  <span className="text-v2primary">
                    {extra?.team_a} : {extra?.team_b}
                  </span>
                </p>
                <p className="text-v2primary small-content ml-0 md:ml-m">
                  Winners are rewarded!
                </p>
              </div>
            )}
          </div>

          {/* Reset button */}
          <div
            className="cursor-pointer flex flex-row items-end"
            onClick={() => setIsPicked(null)}
          >
            <img
              src="/assets/Reset.svg"
              alt="reset"
              className="mr-[5px] w-[10px] mb-[2px]"
            />
            <p className=" small-content">RESET</p>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-center space-x-[10px] w-full mt-[5px]">
        {/* Team A */}
        <div
          className={`${$teamContainer} team-a-container ${
            isPicked === "team_a" && "active"
          }`}
          style={{
            ...(isPicked === "team_a"
              ? {
                  flex: 1,
                }
              : {
                  flex: 1,
                }),
          }}
          onClick={() => selectTeam("team_a")}
        >
          {/* Confirm Btn */}
          <div className="absolute left-[10px] bottom-[10px] z-[50]">
            {!myPick && isPicked === "team_a" && !result && (
              <button className={$confirmBtn} onClick={() => confirmModal()}>
                Confirm
              </button>
            )}

            {!result && isPicked !== "team_a" && (
              <div
                className={$checkBtn}
                style={{
                  backgroundColor: "#2F333F",
                }}
              ></div>
            )}

            {myPick?.predict_team === "team_a" && (
              <div
                className={$checkBtn}
                style={{
                  backgroundColor: "#60C456",
                }}
              >
                <img src="/assets/Tick_Black.svg" alt="black-tick" />
              </div>
            )}
          </div>

          {result === "team_a" && (
            <div className="absolute left-[10px] top-[10px]">
              <h2 className={$teamWinner}>{isResponsive ? "W " : "Winner"}</h2>
            </div>
          )}

          <div className={`${$teamNameWrapper} !pl-[0px]`}>
            <div className="flex flex-row h-[26px] w-full justify-end">
              {(!isPicked || isPicked === "team_a") && (
                <h2 className={$teamName}>
                  {result === "team_a" && (
                    <span className="mr-[5px] text-[#FE5219]">*</span>
                  )}
                  {team_a || "Team A"}
                </h2>
              )}
            </div>

            {(!isPicked || isPicked === "team_a") && (
              <p
                className={`${$teamProbability} ${
                  isPicked === "team_a" && "!text-[#fff]"
                } flex-1`}
              >
                {teamASupportsPerc}% Picked
              </p>
            )}
          </div>

          <div className={`${$teamLogoWrapper}`}>
            <img
              src={team_a_logo || "/assets/GeDa_default.png"}
              className={`${$teamLogo} ${isPicked === "team_b" && "mx-auto"}`}
              alt="team-a-logo"
            />
          </div>
          {!isResponsive && (
            <img
              src={team_a_logo || "/assets/GeDa_default.png"}
              className={$teamLogoBg}
              alt="team-a-grayscale"
            />
          )}
        </div>
        {/* Team B */}
        <div
          className={`${$teamContainer} team-b-container !justify-start ${
            isPicked === "team_b" && "active"
          }`}
          onClick={() => selectTeam("team_b")}
          style={{
            ...(isPicked === "team_b"
              ? {
                  flex: 1,
                }
              : {
                  flex: 1,
                }),
          }}
        >
          {/* Confirm Btn */}
          <div className="absolute right-[10px] bottom-[10px] z-[50]">
            {!myPick && isPicked === "team_b" && !result && (
              <button className={$confirmBtn} onClick={() => confirmModal()}>
                Confirm
              </button>
            )}

            {!result && isPicked !== "team_b" && (
              <div
                className={$checkBtn}
                style={{
                  backgroundColor: "#2F333F",
                }}
              ></div>
            )}

            {myPick?.predict_team === "team_b" && (
              <div
                className={$checkBtn}
                style={{
                  backgroundColor: "#60C456",
                }}
              >
                <img src="/assets/Tick_Black.svg" alt="black-tick" />
              </div>
            )}
          </div>

          {result === "team_b" && (
            <div className="absolute right-[10px] top-[10px]">
              <h2 className={$teamWinner}>{isResponsive ? "W " : "Winner"}</h2>
            </div>
          )}

          <div className={`${$teamLogoWrapper}`}>
            <img
              src={team_b_logo || "/assets/GeDa_default.png"}
              className={`${$teamLogo} ${isPicked === "team_b" && "mx-auto"}`}
              alt="team-b-logo"
            />
          </div>
          <div className={`${$teamNameWrapper} !pr-[0px] !items-start`}>
            <div className="flex flex-row h-[24px] w-full">
              {(!isPicked || isPicked === "team_b") && (
                <h2 className={`${$teamName} !text-left`}>
                  {team_b || "Team B"}
                  {result === "team_b" && (
                    <span className="ml-[5px] text-[#FE5219]">*</span>
                  )}
                </h2>
              )}
            </div>
            {(!isPicked || isPicked === "team_b") && (
              <p
                className={`${$teamProbability} ${
                  isPicked === "team_b" && "!text-[#fff]"
                }`}
              >
                {teamBSupportsPerc}% Picked
              </p>
            )}
          </div>
          {!isResponsive && (
            <img
              src={team_b_logo || "/assets/GeDa_default.png"}
              className={$teamLogoBg}
              alt="team-b-grayscale"
            />
          )}
        </div>
      </div>

      <ModalConfirmPrediction
        isModalOpen={openPredictionModal}
        setIsModalOpen={setOpenPredictionModal}
        isPicked={isPicked}
        game={game}
        myPick={myPick}
        fetchGame={fetchGame}
        getMyPickCb={getMyPickCb}
      />
    </div>
  );
};

const $teamContainer =
  "flex-1 flex flex-row justify-end py-[10px] px-[10px] bg-[#1D1D1D] items-center rounded-m relative overflow-hidden cursor-pointer hover:bg-[#2F333F] transition-all duration-700 relative h-[70px] md:h-[104px]";

const $teamNameWrapper =
  "flex flex-col items-end px-[10px] relative z-[15] w-full ";
const $teamName =
  "mb-[0px] text-ellipsis text-right flex-1 md:big-conent overflow-hidden max-w-[100px] md:max-w-none text-[13px]";
const $teamProbability = "text-[#949494] text-[10px] md:small-content mt-xs";
const $teamLogoWrapper =
  "min-w-[35px] min-h-[35px] md:min-w-[70px] md:min-h-[70px] w-[35px] h-[35px] md:h-[70px] md:w-[70px] overflow-hidden relative z-[15] transition-all duration-700";
const $teamLogo =
  "w-full h-full object-cover relative z-[15] transition-all duration-700 p-[5px]";
const $teamLogoBg =
  "opacity-[50%] left-[50%] absolute top-[50%] translate-x-[-50%] translate-y-[-50%] w-[250px] min-w-[250px] max-w-[250px]";
const $teamWinner =
  "text-[12px] md:text-[18px] leading-[12px] md:leading-[18px] mb-[0px] text-ellipsis self-start flex-1 justify-self-start text-[#FE5219]";
const $confirmBtn =
  "bg-[#FE5219] border-[0px] px-[10px] py-[1px] text-[#fff] rounded-[5px] text-[10px] md:text-[12px]";
const $checkBtn = "border-[0px] w-[15px] h-[15px] rounded-[5px] p-[3px]";
