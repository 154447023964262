import axios from "axios";
import * as Configs from "../config";
import { errorHandling, setHeader } from "./api";
export class ReferralStore {
  async getReferralProfile() {
    const url = `${Configs.API_URL}/v1/referral/me`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async doReferral(userId: string, code: string) {
    const url = `${Configs.API_URL}/v1/referral/enter-code`;
    try {
      const result: any = await axios.post(url, { userId, code }, setHeader());
      return result?.data?.msg;
    } catch (e) {
      errorHandling(e);
      return false;
    }
  }
}
export const STORE_REFERRAL = "referralStore";
