import { useWeb3Modal } from "@web3modal/react";
import { history } from "src/stores";
import { AuthStore } from "src/stores/auth";
import { useAccount, useDisconnect } from "wagmi";

export const Nav = (props: any) => {
  const { invisibleHeader = false } = props;

  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const authStore = new AuthStore();

  const handleLogout = () => {
    authStore.logout();
    disconnect();
  };

  return (
    <div
      className="fixed w-full px-[15px] md:px-[30px] py-[16px] flex flex-row z-[100] min-h-[60px] max-h-[60px] border-b-[2px] border-[#11131E]"
      style={{
        ...(invisibleHeader && {
          background: "transparent",
          borderColor: "transparent",
        }),
        ...(!invisibleHeader && {
          background: "#1B1D28",
        }),
      }}
    >
      {/* Logo */}
      <div className="min-w-[137px] md:min-w-[200px] flex-1">
        <img
          src="/assets/logo.png"
          className="h-full object-cover cursor-pointer"
          alt="GeDa"
          onClick={() => history.push("/")}
        />
      </div>

      {/* Menu */}
      <div className="flex flex-row flex-1 space-x-[30px] justify-center items-center desktop">
        <p
          className={$headerMenuStyle}
          onClick={() => history.push("/prediction")}
          style={{
            ...(window.location.href.includes("prediction") && {
              color: "#FE5218",
            }),
          }}
        >
          Predictions
        </p>
        <p
          className={$headerMenuStyle}
          onClick={() => history.push("/raffle")}
          style={{
            ...(window.location.href.includes("raffle") && {
              color: "#FE5218",
            }),
          }}
        >
          Raffles
        </p>
        <p
          className={$headerMenuStyle}
          onClick={() => window.open("https://geda.gg", "_self")}
        >
          Tournaments
        </p>
      </div>

      {/* Connect Wallet Icons, Minified & Close Icons */}
      <div className="flex flex-row flex-1 justify-end">
        {!address && (
          <button className={$btnStyle} onClick={() => open()}>
            Wallet
          </button>
        )}

        {address && (
          <>
            <div className="mt-[6px] mr-[12px] flex flex-row">
              <p className="text-[#4D515D] mr-[10px] desktop">Hi, </p>
              <p className="desktop text-[#4D515D] overflow-hidden text-ellipsis w-[50px] sm:w-[100px]">
                {address}
              </p>
            </div>
            <button
              className={`${$btnStyle} min-w-[100px] mr-[10px]`}
              onClick={() => history.push("/my-bag")}
            >
              My Bag
            </button>
            <button
              className={`${$btnStyle} desktop`}
              onClick={() => handleLogout()}
            >
              Disconnect
            </button>
          </>
        )}
      </div>
    </div>
  );
};

const $btnStyle =
  "bg-white text-[#11131E] font-[600] px-[20px] leading-[12px] py-[5px]";
const $headerMenuStyle = "cursor-pointer uppercase";
