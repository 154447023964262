import { history } from "src/stores";
import { GedaBtn } from "./geda-btn";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useDisconnect } from "wagmi";
import { AuthStore } from "src/stores/auth";

interface MobileNavType {
  openDrawer: boolean;
  setOpenDrawer: (value: boolean) => void;
}

interface MenuListType {
  name: string;
  toPage?: string;
  url?: string;
  urlType?: string;
  action?: () => void;
}

export const MobileNav = (props: MobileNavType) => {
  const { openDrawer, setOpenDrawer } = props;

  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const authStore = new AuthStore();

  const handleLogout = () => {
    authStore.logout();
    disconnect();
  };

  const menuList: MenuListType[] = [
    {
      name: "discover",
      toPage: "/",
    },
    {
      name: "about-us",
      toPage: "/about-us",
    },
    {
      name: "tournaments",
      url: "https://tournament.geda.gg",
      urlType: "_self",
    },
    {
      name: "Watch & predict",
      toPage: "/prediction",
    },
    {
      name: "raffles",
      toPage: "/raffle",
    },
    {
      name: "earn",
      toPage: "/airdrop",
    },
    {
      name: "events",
      url: "https://expo.geda.gg/",
      urlType: "_self",
    },
    {
      name: "my bag",
      toPage: "/my-bag",
    },
    {
      name: address ? "disconnect" : "connect wallet",
      action: () => {
        address && handleLogout();
        !address && open();
      },
    },
    {
      name: "close menu",
      action: () => setOpenDrawer(false),
    },
  ];

  return (
    <div className="relative w-full flex flex-row z-[100] items-center justify-between bg-black mobile">
      <img
        src="/assets/v3/mobile-logo.png"
        alt="mobile-logo"
        className="w-[126px] h-[48px]"
      />
      <p className="absolute left-[130px] bottom-0 blender-medium small-content uppercase">
        Beta
      </p>

      <GedaBtn txt="MENU" action={() => setOpenDrawer(true)} />

      {/* Mobile Drawer */}
      <div
        className="fixed top-0 left-0 h-screen z-[9999] bg-v2primary transition-all duration-700 p-m pt-[50px] overflow-auto"
        style={{
          width: openDrawer ? "100vw" : "0px",
          left: openDrawer ? "0px" : "100vw", // Slide in from right when open
        }}
      >
        <p className={`${$menuTxt} text-center`}>Menu</p>
        <div className={`${$divider} my-m`} />
        {menuList.map((item, idx) => {
          return (
            <div key={idx} className="pt-m">
              <div
                className="flex flex-row justify-between"
                onClick={() => {
                  item?.toPage && history.push(item?.toPage);
                  item?.url && window?.open(item?.url, item?.urlType);
                  item?.action && item?.action();
                }}
              >
                <p className={`${$menuTxt} min-w-[200px]`}>{item?.name}</p>
                <p className={`${$menuTxt}`}>{`>`}</p>
              </div>
              <div className={`${$divider} my-[10px]`} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const $menuTxt = "blender-medium text-[16px] leading-[19px] uppercase";
const $divider = "h-[1px] w-full bg-[#fff]/50";
