import { Modal } from "antd";
import { CloseButton } from "./close-btn";
import { useState } from "react";
import { LoadingIcon } from "./loading-icon";
import { useAuthStore } from "src/stores";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";

const w3Items = [
  "acident8",
  "acident8gw",
  "nyannft",
  "guardiannft",
  "mixmobnft",
  "pomeriumnft",
  "pomeriumtoken",
  "iagentnode",
  "kukubox",
  "firebox",
  "usdt10",
  "usdt50",
  "usdt100",
  "ssnft",
  "cellulanft",
];

export const ModalClaimForm = (props: any) => {
  const { isModalOpen, setIsModalOpen, item, getMyBagCb } = props;

  const [contactName, setContactName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { claimPrize } = useAuthStore();

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const submitForm = async () => {
    if (!contactName || !email)
      return openWarningNotification("Please fill in all fields");
    if (isLoading) return;
    setIsLoading(true);
    const result = await claimPrize({
      prizeId: item?.prize?._id,
      form: {
        contactName,
        email,
        address,
      },
    });
    setIsLoading(false);
    if (!result) return;
    getMyBagCb();
    openSuccessNotification("You have submitted the form, please stay tuned!");
    setIsModalOpen(false);
  };

  const getCongrazTxt = () => {
    if (w3Items.indexOf(item?.prize_detail?.game_id) > -1) {
      return "Congratulations! Please fill out this form immediately to secure your spot. If you do not complete this form in time, you may miss out on this opportunity!";
    }

    return "Congratulations! We will reach out to you for prize claiming in the coming days after you submit this form.";
  };

  const getChainName = () => {
    if (["nyannft", "guardiannft", "mixmobnft"].indexOf(item?.extra) > -1) {
      return "SOL";
    }
    if (["pomeriumnft", "firebox", "kukubox"].indexOf(item?.extra) > -1) {
      return "Polygon";
    }
    if (
      ["pomeriumnft", "pomeriumtoken", "cellulanft"].indexOf(item?.extra) > -1
    ) {
      return "BNB";
    }
    return "ERC20";
  };

  return (
    <Modal
      title={``}
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      centered={true}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleCancel} />

      <div className="flex flex-col justify-start align-center min-h-[200px]">
        {/* Title */}
        <h2 className="page-subtitle">Claim your Prize</h2>
        {/* Form */}
        <div className="mt-s">
          <div>
            <p className={$labelStyle}>Full Name</p>
            <input
              type="normal"
              name="contactName"
              className={$inputClass}
              placeholder=""
              value={contactName}
              onChange={(e) => {
                setContactName(e.target.value);
              }}
            />
          </div>
          <div className="mt-xs">
            <p className={$labelStyle}>Email Address</p>
            <input
              type="email"
              name="email"
              className={$inputClass}
              placeholder="abc@gmail.com"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          {w3Items.indexOf(item?.extra) > -1 && (
            <div className="mt-xs">
              <p className={$labelStyle}>
                Wallet Address for Minting ({getChainName()})
              </p>
              <input
                type="text"
                name="wallet-address"
                className={$inputClass}
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </div>
          )}
          {/* Confirm Button */}
          <div className="mt-[24px]">
            <button
              className="bg-gradient-to-r from-[#F99433] to-[#FE5218] text-white  px-[20px] py-[10px] border-[0px] mx-auto w-full md:w-[300px] justify-center flex"
              onClick={() => submitForm()}
            >
              {isLoading ? <LoadingIcon color="#fff" size={20} /> : "Confirm"}
            </button>
          </div>
          {/* Notes */}
          <p className="mt-s text-center small-content">{getCongrazTxt()}</p>
        </div>
      </div>
    </Modal>
  );
};

const $labelStyle = "content";
const $inputClass =
  "mt-1 px-s py-[6px] bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#783CC7] focus:ring-[#783CC7] block w-full rounded-md text-s focus:ring-1 rounded-m";
