import { ContainerV2 } from "src/components/container-v2";
import { LoadingIcon, openErrorNotification } from "../components";
import { useCallback, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { useGameStore } from "src/stores";
import { PREDICTION_LIST_V3 } from "src/config/predictions";
import { GamePrediction } from "src/components/game-prediction";
import { useWindowSize } from "@uidotdev/usehooks";
import { Tag } from "src/components/tag";

export const PredictionV3Detail = (props: any) => {
  const { getPredictionList } = useGameStore();

  const [currentLive, setCurrentLive] = useState<any>(null);
  const [filterLives, setFilterLives] = useState<any>([]);
  const [predictionGames, setPredictionGames] = useState<any>(null);
  const [streamLang, setStreamLang] = useState<"en" | "cn">("en");
  const [allLoaded, setAllLoaded] = useState<boolean>(false);
  const [showLiveOnly, setShowLiveOnly] = useState<boolean>(true);

  const { address } = useAccount();
  const size: any = useWindowSize();

  const fetchGame = useCallback(
    async (gameId) => {
      try {
        const gameList = await getPredictionList(gameId);
        setPredictionGames(gameList);
      } catch (e) {
        setPredictionGames([]);
        openErrorNotification(
          "Cannot get the predictions,please try again later"
        );
      }
    },
    [getPredictionList]
  );

  useEffect(() => {
    fetchGame(props.match?.params?.id);
  }, [fetchGame, props.match?.params?.id]);

  useEffect(() => {
    setCurrentLive(
      PREDICTION_LIST_V3.filter(
        (item) => item.gameId === props.match?.params?.id
      )[0]
    );
    setFilterLives(PREDICTION_LIST_V3);
  }, [props.match?.params?.id]);

  useEffect(() => {
    // Initialize your script here
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    document.body.appendChild(script);

    // Clean up function (optional)
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array means this effect runs only once, similar to componentDidMount

  const switchLive = (liveItem: any) => {
    setCurrentLive(liveItem);
    window.history.replaceState(
      null,
      "",
      window.location.origin + "/prediction-v3/" + liveItem.gameId
    );
    fetchGame(liveItem.gameId);
  };

  // remove the live item in the list
  useEffect(() => {
    if (currentLive) {
      const lives = PREDICTION_LIST_V3.filter(
        (live: any) => live.gameId !== currentLive.gameId && live.isSelectable
      );

      setFilterLives(lives);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(currentLive)]);

  useEffect(() => {
    if (!predictionGames) return;
    setAllLoaded(true);
  }, [predictionGames]);

  return (
    <ContainerV2>
      <h1 className="page-title mt-l">
        LIVE <span className="text-v2primary">PREDICTIONS</span>
      </h1>

      <div className="mt-s flex flex-row">
        <button
          onClick={() => {
            setStreamLang("en");
            // window?.location?.reload();
          }}
          className={`${$btnStyle} ${
            streamLang === "en"
              ? "bg-v2primary text-white"
              : "bg-white text-black"
          }`}
        >
          English
        </button>

        <button
          onClick={() => setStreamLang("cn")}
          className={`${$btnStyle} ml-s ${
            streamLang === "cn"
              ? "bg-v2primary text-white"
              : "bg-white text-black"
          }`}
        >
          中文
        </button>
      </div>

      <div className="mt-s">
        {/* left */}
        <div className="flex flex-col flex-1">
          {currentLive?.youtubeLive && (
            <>
              {/* live stream */}
              {streamLang === "en" && (
                // <div
                //   dangerouslySetInnerHTML={{ __html: currentLive?.twitter }}
                // />
                <iframe
                  className="md:h-[544px] rounded-[24px]"
                  src={currentLive?.youtubeLive}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title={"GeDa Live"}
                />
              )}

              {streamLang === "cn" && (
                <iframe
                  className="md:h-[544px] rounded-[24px]"
                  src={currentLive?.bilibili}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title={"GeDa Live"}
                />
              )}
            </>
          )}

          <div className="flex flex-row mt-m justify-between">
            <h2 className="blender-medium page-subtitle !mb-0 min-w-[200px]">
              GEDA GAME ZONE
            </h2>
            <div className="flex flex-row">
              <Tag tag={currentLive?.type[0]} />
              {currentLive?.isLive && <Tag tag="Live" />}
              {currentLive?.isEnded && <Tag tag="Ended" />}
            </div>
          </div>

          <div className="mt-s">
            <p className="small-content">{currentLive?.desc}</p>
          </div>

          <div className="mt-l flex flex-row">
            <div className="relative z-[15] flex-1">
              {!allLoaded && (
                <div className="w-full h-full flex flex-1 justify-center items-start relative z-[15]">
                  <LoadingIcon />
                </div>
              )}

              {allLoaded && (
                <>
                  <div className="flex flex-row justify-end">
                    <button
                      onClick={() => setShowLiveOnly(!showLiveOnly)}
                      className={`bg-v3secondary border-none`}
                    >
                      {showLiveOnly ? "Show Past Results" : "Show Live Games"}
                    </button>
                  </div>
                  <div className="mt-s">
                    <GamePrediction
                      predictionList={predictionGames}
                      address={address}
                      fetchGame={() => fetchGame(props.match?.params?.id)}
                      showLiveOnly={showLiveOnly}
                    />
                  </div>
                </>
              )}
            </div>

            {/* right, prediction list
             */}
            {filterLives && filterLives.length > 0 && size.width > 1080 && (
              <div className="flex-[0.3] ml-m">
                <h2 className="blender-medium page-subtitle !mb-0">
                  Other Games
                </h2>
                <div className="flex flex-col mt-l">
                  {filterLives?.map((item: any) => {
                    // Adjust the number here to limit the total number of items rendered
                    const {
                      gameId,
                      avatar,
                      type = ["web2"],
                      name,
                      desc,
                      isLive,
                      isEnded,
                    } = item;

                    return (
                      <div
                        key={gameId}
                        className="flex items-start flex-row w-[611px] h-[160px] relative mb-m cursor-pointer bg-[#1D1D1D] rounded-m overflow-hidden"
                        onClick={() => switchLive(item)}
                      >
                        {/* background images */}
                        <img
                          className="relative w-[250px] h-full left-0 z-0 object-cover"
                          src={avatar}
                          alt="logo-bg"
                        />

                        {/* top / left */}
                        <div className="absolute flex items-start justify-start w-[250px] h-[160px] z-[999] md:p-xs">
                          {/* reward type label */}
                          <Tag tag={type[0]} />
                          {isLive && <Tag tag="Live" />}
                          {isEnded && <Tag tag="Ended" />}
                        </div>

                        {/* bottom / right */}
                        <div className="flex flex-col z-[999] p-s">
                          <p className="big-content">{name}</p>
                          <p className="content">{name}</p>
                          <div className="flex flew-row mt-xs">
                            <div className="flex justify-center items-center border-[1px] border-white rounded-sm p-[1px] px-[4px] w-fit">
                              <p>{desc}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ContainerV2>
  );
};

const $btnStyle = `
  cursor-pointer
  w-[85px] 2xl:w-[125px] 
  rounded-[5px] 2xl:rounded-[12px] 
  h-[29px] 2xl:h-[56px] 
  flex flex-row items-center 
  justify-center 
  text-[12px] 2xl:text-[20px] 
  leading-[14px] 2xl:leading-[24px] 
  blender-medium
  border-0
`;
